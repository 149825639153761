// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/grid';

// Component styles
.c-hero__subtitle .c-disclaimer-cta {
  font-size: 0.8em !important;
}

.hero {
  $eyebrow-font-size: 14px;
  $hero-height-sm: 317px;
  $hero-height-md: 445px;
  $hero-height-lg: 516px;

  $hero-low-height-sm: 200px;
  $hero-low-height-md: 250px;
  $hero-low-height-lg: 300px;

  @include make-container();

  font-family: $ted-next;
  height: $hero-height-sm;

  @include m('low-profile') {
    height: $hero-low-height-sm;
    flex-direction: column;
  }

  @include m('lead-form-hero') {
    height: 350px;

    @include e('heading') {
      max-width: 100%;
    }
  }

  @include m('wide-text-v1') {
    @include e('heading') {
      max-width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    height: $hero-height-md;

    @include m('low-profile') {
      height: $hero-low-height-md;
    }
  }

  @include media-breakpoint-up(lg) {
    height: $hero-height-lg;

    @include m('low-profile') {
      height: $hero-low-height-lg;
    }
  }

  @include e('hero-body') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 95%;
    margin: auto;

    @include media-breakpoint-up(md) {
      max-width: 992px;
    }
  }

  @include e('aligned') {
    width: 100%;
  }

  @include e('heading') {
    @include make-col(12);
    @include h1();
    text-align: center;

    height: 100%;
    margin: 0 auto 15px;

    @include media-breakpoint-up(md) {
      margin: 0 auto 15px;
    }

    @include media-breakpoint-up(lg) {
      @include make-col(9);
    }
  }

  @include e('eyebrow') {
    display: block;
    margin: 15px auto 10px;
    font-weight: $demibold;
    text-align: center;
    font-size: $eyebrow-font-size;
    letter-spacing: 5px;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      width: 100%;
      height: 24px;
      margin-bottom: 15px;
    }
  }

  &__subtitle {
    @include hero-subheading();

    margin-bottom: 15px;
    line-height: 1.6em;

    .c-disclaimer-cta,
    sup {
      font-size: 0.8em !important;
    }
  }

  @include e('button-wrap') {
    text-align: center;
    align-items: center;
    display: flex;
    flex-flow: column;
    padding-top: 10px;
  }

  &.hero--with-breadcrumbs {
    min-height: 0;
    .hero__hero-body {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .support-article__breadcrumbs,
    .support-product-articles__breadcrumbs {
      max-width: 1160px;
      width: 100%;
      box-shadow: none;
      margin-top: 36px;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: minmax(max-content, auto) 1fr;
      grid-template-areas: 'crumbs pad';
      @include media-breakpoint-up(md) {
        min-width: 0;
      }
      @include media-breakpoint-only(sm) {
        grid-template-columns: 1fr;
      }

      .support-article__breadcrumbs-container,
      .support-product-articles__breadcrumbs-container {
        font-size: 0;
        grid-area: crumbs;
        display: flex;
        justify-self: flex-start;
        width: auto;
        margin-left: 0;
        padding: 15px 20px;
        border-radius: 4px;
        background-color: #cac9c7;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
      }
      ul {
        margin: 0;
        padding: 0;
      }
      li {
        color: map-get($rogers-colors, body);
        line-height: 1em;
        font-weight: $demibold;

        svg.caret {
          transform: rotate(-90deg);
          display: inline-block;
          margin: 0 5px;
        }
      }
      a {
        outline: none;
        color: map-get($rogers-colors, body);
        font-family: $ted-next;
        font-weight: $demibold;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
