// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';

span.rich-text > p {
  display: inline;
}
@include b(rich-text) {
  img.center {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }
  p + p {
    margin-top: 15px;
  }
  .bold {
    font-weight: bold;
  }
  .italic {
    font-style: italic;
  }
  [data-disclaimer] > sup {
    margin-right: 5px;
    top: -0.5em;
  }
  a {
    color: map-get($rogers-colors, link-light);
    text-decoration: none;
    .no-touch &:hover {
      text-decoration: underline;
      color: map-get($rogers-colors, link-light-hover);
    }
    &.footer-link {
      margin-left: 5px;
    }
  }
  table {
    display: table;
    border-collapse: collapse;
    th,
    td {
      text-align: left;
      padding: 5px 15px;
      border: 1px solid $color-light-grey;
    }
    th {
      padding: 10px 15px;
      background: $color-white;
    }
    &.noborder {
      th,
      td {
        border-color: transparent;
      }
    }
  }
}
