// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/globals/icons';
@import 'style/grid/grid';

// Application styles
@import '../Icon/shawIcons.css';

.scotchButton {
  &:active,
  &:focus {
    outline: none !important;
  }
  .button__text {
    margin-top: 0;
  }
}
.scotchButtonHover {
  // padding: 15px 30px;
  > span {
    text-decoration: underline;
  }
}

.baseButtonHover {
  border: 0;
  padding: 12px 40px;
  color: white;
  // Design system will add hc color later!
  background: linear-gradient(
    90deg,
    map-get($colors-gradient, hover-start),
    map-get($colors-gradient, hover-end)
  );
}
// Component styles
.button {
  $regular-font-size: 14px;
  $button-font-size: 16px;

  @mixin box {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family:  $ted-next;
    font-weight: $demibold;
    padding: 12px 40px;
    border-width: 0;
    cursor: pointer;
    transform: scale(1);
    text-align: center;
    font-size: $button-font-size;
    background: transparent;
    transition: transform $default-timing;
    backface-visibility: hidden;

    // @include media-breakpoint-only(sm) {
    //   padding-left: $smaller-padding;
    //   padding-right: $smaller-padding;
    // }

    // @include media-breakpoint-up(md) {
    //   padding: 0 $smaller-padding;
    // }
    // @include media-breakpoint-up(lg) {
    //   padding: 0 $regular-padding;
    //   &.has-icon {
    //     padding: 0 $smaller-padding;
    //   }
    // }


    &:disabled {
      opacity: 0.3;
    }
  }

  //  &:hover:not([disabled]) {
  //   &::before {
  //     transform: scale(1.02);
  //   }
  // }

  @include m('scotch-primary') {
    @include box;
    @extend .scotchButton;
    &.button--light {
      color: map-get($rogers-colors, white);
      background: map-get($rogers-colors, primary-light);
      &:hover {
        @extend .scotchButtonHover;
      }
    }

    &.button--outline {
      color: map-get($rogers-colors, primary-light) !important;
      border: 2px solid map-get($rogers-colors, primary-light) !important;
      padding: 10px 38px;
      background: transparent;
      &:hover {
        @extend .scotchButtonHover;
      }
    }

    &.button--dark {
      color: map-get($rogers-colors, primary-light) !important;
      background: map-get($rogers-colors, white);
      &:hover {
        @extend .scotchButtonHover;
        color: map-get($rogers-colors, primary-light-hover) !important;
        .button__text {
          color: map-get($rogers-colors, primary-light-hover) !important;
        }
      }
    }
    .product-plan-card-carousel__cta-text {
      margin-top: 0;
    }
  }

  @include m('scotch-secondary') {
    @include box;
    @extend .scotchButton;
    &.button--light {
      color: map-get($rogers-colors, secondary-light) !important;
      border: 2px solid map-get($rogers-colors, secondary-light);
      padding: 10px 38px;
      background: map-get($rogers-colors, white);
      &:hover {
        @extend .scotchButtonHover;
        color: map-get($rogers-colors, secondary-light);
        border: 2px solid map-get($rogers-colors, secondary-light);
      }
    }
    &.button--outline {
      color: map-get($rogers-colors, secondary-light) !important;
      border: 2px solid map-get($rogers-colors, secondary-light);
      padding: 10px 38px;
      background: transparent;
      &:hover {
        @extend .scotchButtonHover;
        color: map-get($rogers-colors, secondary-light);
        border: 2px solid map-get($rogers-colors, secondary-light);
      }
    }
    &.button--dark {
      color: map-get($rogers-colors, white);
      border: 2px solid map-get($rogers-colors, white);
      padding: 10px 38px;
      background: transparent;
      &:hover {
        @extend .scotchButtonHover;
      }
    }
    .product-plan-card-carousel__cta-text {
      margin-top: 0;
    }
  }
  @include m('scotch-link') {
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    font-weight: $demibold;
    &:hover,
    &:focus {
      outline: none;
      text-decoration: underline;
    }
    &.button--light,
    &.button--outline {
      color: map-get($rogers-colors, secondary-light) !important;
      &::after {
        transition: background 0.1s ease-in-out;
        display: inline-block;
        transform: rotate(-90deg) translate(-1px, 3px);
        content: '';
        height: 16px;
        width: 16px;
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 0 4px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSI4LjA3NCIgdmlld0JveD0iMCAwIDE0IDguMDc0Ij4KICA8ZyBpZD0iSWNvbnNfTWlzY19Eb3duQXJyb3ciIGRhdGEtbmFtZT0iSWNvbnMvTWlzYy9Eb3duQXJyb3ciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMS4wNzQpIj4KICAgIDxnIGlkPSJJY29uc19NaXNjX0Rvd25BcnJvdy0yIiBkYXRhLW5hbWU9Ikljb25zL01pc2MvRG93bkFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMikgcm90YXRlKDkwKSI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzg2NSIgZGF0YS1uYW1lPSJQYXRoIDg2NSIgZD0iTS4xMTYsMTEuODc3YS40NDUuNDQ1LDAsMCwxLDAtLjZMNS4wNTMsNi4wMDYuMTE2LjcyNGEuNDQ1LjQ0NSwwLDAsMSwwLS42LjM3OC4zNzgsMCwwLDEsLjU2LDBMNS44ODQsNS43QS40MjcuNDI3LDAsMCwxLDYsNmEuNDQ3LjQ0NywwLDAsMS0uMTE2LjNMLjY3NiwxMS44NjdBLjM3MS4zNzEsMCwwLDEsLjExNiwxMS44NzdaIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDdiYmEiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==');
      }
      &:hover,
      &:focus {
        &:after {
          background-position: 0 5px;
        }
      }
    }
    &.button--dark {
      color: map-get($rogers-colors, body) !important;
      &::after {
        transition: background 0.1s ease-in-out;
        display: inline-block;
        transform: rotate(-90deg) translate(-1px, 3px);
        content: '';
        height: 16px;
        width: 16px;
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 0 4px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSI4LjA3NCIgdmlld0JveD0iMCAwIDE0IDguMDc0Ij4KICA8ZyBpZD0iSWNvbnNfTWlzY19Eb3duQXJyb3ciIGRhdGEtbmFtZT0iSWNvbnMvTWlzYy9Eb3duQXJyb3ciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMS4wNzQpIj4KICAgIDxnIGlkPSJJY29uc19NaXNjX0Rvd25BcnJvdy0yIiBkYXRhLW5hbWU9Ikljb25zL01pc2MvRG93bkFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMikgcm90YXRlKDkwKSI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzg2NSIgZGF0YS1uYW1lPSJQYXRoIDg2NSIgZD0iTS4xMTYsMTEuODc3YS40NDUuNDQ1LDAsMCwxLDAtLjZMNS4wNTMsNi4wMDYuMTE2LjcyNGEuNDQ1LjQ0NSwwLDAsMSwwLS42LjM3OC4zNzgsMCwwLDEsLjU2LDBMNS44ODQsNS43QS40MjcuNDI3LDAsMCwxLDYsNmEuNDQ3LjQ0NywwLDAsMS0uMTE2LjNMLjY3NiwxMS44NjdBLjM3MS4zNzEsMCwwLDEsLjExNiwxMS44NzdaIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==');
      }
      &:hover,
      &:focus {
        &:after {
          background-position: 0 5px;
        }
      }
    }
  }

  @include m('scotch-link-small') {
    text-decoration: none;
    font-size: 14px;
    font-weight: $demibold;
    &:hover,
    &:focus {
      outline: none;
      span {
        text-decoration: underline;
      }
      // text-decoration: underline;
    }
    &.button--light,
    &.button--outline {
      color: map-get($rogers-colors, secondary-light) !important;
    }
    &.button--dark {
      color: map-get($rogers-colors, body) !important;
    }
  }

  @include m(primary) {
    @include box;

    color: $color-white;

    &::before {
      background-color: $color-primary-cta;
    }

    &:hover:not([disabled]) {
      &::before {
        background: linear-gradient(
          90deg,
          map-get($colors-gradient, hover-start),
          map-get($colors-gradient, hover-end)
        );
      }
    }

    .icon {
      color: $color-white;
      margin-left: 5px;
      z-index: 99999;
    }
  }

  @include m(secondary) {
    @include box;

    color: $color-primary-cta;
    figure {
      max-width: 100%;
    }
    .icon {
      margin-left: 5px;
      z-index: 1;
      color: $color-primary-cta;
      width: 30px;
      height: 30px;
    }
    svg.icon {
      fill: currentColor;
    }

    &::before {
      background: $color-secondary-cta;
      border: 2px solid $color-primary-cta;
      box-sizing: border-box;
    }

    &:hover:not([disabled]) {
      color: $color-white;
      svg.icon {
        fill: currentColor;
      }
      .icon {
        color: $color-white;
        margin-left: 5px;
        z-index: 99999;
        &:before {
          color: $color-white;
        }
      }

      &::before {
        border: 0;

        background: linear-gradient(
          90deg,
          map-get($colors-gradient, hover-start),
          map-get($colors-gradient, hover-end)
        );
        box-sizing: border-box;
      }
    }
  }

  @include m(tertiary) {
    @include box;

    color: $color-primary-cta;

    &::before {
      background-color: white;
    }

    &:hover:not([disabled]) {
      color: white;
      &::before {
        border: 0;

        background: linear-gradient(
          90deg,
          map-get($colors-gradient, hover-start),
          map-get($colors-gradient, hover-end)
        );
      }
    }

    .icon {
      color: $color-primary-cta;
      margin-left: 5px;
      z-index: 99999;
    }
  }
  @include m(quaternary) {
    @include box;

    color: white;

    &:hover:not([disabled]) {
      color: white;
    }

    .icon {
      color: white;
      margin-left: 5px;
      z-index: 99999;
    }
  }

  &--link-caret,
  &--link-caret-contrast {
    font-size: $regular-font-size;
    color: $color-primary-cta;
    text-decoration: none;
    font-weight: $demibold;

    .button--icon::before {
      color: $color-primary-cta;
    }

    .button--icon.icon.icon-#{map_get($icons, offsite)} {
      margin: 0;
      padding: 0;
      font-size: 19px;
      position: relative;
      left: -4px;
    }

    .button--icon {
      display: inline-block;
      transform: translate3d(0, 3px, 0);
    }

    &:hover {
      color: map-get($rogers-colors, secondary-light-hover);
      text-decoration: underline;
      font-weight: $demibold;

      .button--icon {
        display: inline-block;
        transform: translate3d(4px, 3px, 0);
      }

      .button--icon.icon.icon-#{map_get($icons, offsite)} {
        display: inline-block;
        transform: translate3d(0, 3px, 0);
      }
    }
  }

  &--link-caret-contrast {
    color: $color-azure-contrast;
  }

  &--link-caret-white {
    color: white;
    text-decoration: none;

    .button--icon::before {
      color: white;
    }

    .button--icon.icon-#{map_get($icons, chevronWhite)} {
      display: inline-block;
      transform: translate3d(0, 3px, 0);
    }

    &:hover {
      text-decoration: underline;
      font-weight: $demibold;

      .button--icon.icon-#{map_get($icons, chevronWhite)} {
        display: inline-block;
        transform: translate3d(4px, 3px, 0);

        &::before {
          color: $color-fog;
        }
      }
    }
  }

  &--link {
    font-size: $regular-font-size;
    color: $color-primary-cta;
    text-decoration: none;
    font-weight: $demibold;

    &--is-active {
      font-weight: $demibold;
    }

    &:hover {
      color: map-get($rogers-colors, link-light-hover);
      text-decoration: underline;
    }

    .button--icon.icon-#{map_get($icons, offsite)} {
      margin: 0;
      padding: 0;
    }
  }

  &--link-white {
    font-size: $regular-font-size;
    font-weight: $demibold;
    color: white;
    text-decoration: none;
    background: none;
    border: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &--icon {
    font-weight: bold;
    display: inline-block;
    will-change: transform;
    transition: transform 0.3s;
    transform: translate(0, 0);
    margin-left: 6px;
    padding-top: 4px;

    @include media-breakpoint-up(md) {
      margin-right: 2px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 4px;
    }
  }

  &:disabled {
    opacity: 0.2;
    cursor: default;
  }

  &__text {
    z-index: 1;
    line-height: 1;
    margin-top: 4px;

    @include media-breakpoint-up(lg) {
      width: 100%;
    }

    &--is-bold {
      font-weight: $bold;
    }
  }
}
