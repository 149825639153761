// Application mixins
@import 'style/globals/animations';
@import 'style/utils/bem';

// Component requirements
@import './shawIcons.css';

// Module styles
@include b(icon) {
  display: inline-block;

  $rotation-step: 90;
  $rotation-steps: 360 / $rotation-step;

  @for $i from 0 through $rotation-steps {
    $rotation: $i * $rotation-step;

    &-rotate-#{$rotation} {
      transform: rotate($rotation * 1deg);
    }
  }
}

$icon_size_map: (
  'icon icon-Product_': 36px,
  'icon icon-ProductFeatures_': 40px,
  'icon icon-ProductFeature_': 40px,
  'icon icon-Link_': 20px,
  'icon icon-Nav_': 20px,
  'icon icon-Global_': 40px,
  'icon icon-smart_': 40px,
  'icon icon-billing': 36px
);

@each $iconClassPrefix, $iconSize in $icon_size_map {
  span[class^='#{$iconClassPrefix}'] {
    &::before {
      font-size: $iconSize;
    }
  }
}

.icon.icon-Product_SmartWiFi {
  &:before {
    font-size: 30px;
  }
}
