@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(product-plan-card) {
  width: 100%;
  height: 100%;
  position: relative;

  @include e(scene) {
    max-width: 346px;
    min-width: 346px;
    // margin: 40px 0;
    perspective: 100vh;
    @include media-breakpoint-down(md) {
      max-width: 310px;
      min-width: 310px;
    }
    @include m(scotch) {
      .product-plan-card__card {
        .product-plan-card__product-name {
          .product-plan-card__banner {
            background: map-get($rogers-colors, primary-light);
            .rich-text {
              font-size: 10px;
            }
          }
          .rich-text {
            font-family: $ted-next;
            font-size: 24px;
            line-height: 28px;
            text-transform: none;
            font-weight: 600;
          }
        }
        .price-information-new__offer {
          background: none;
          border: 3px solid map-get($rogers-colors, primary-light);
          color: map-get($rogers-colors, primary-light);
          .rich-text {
            .c-disclaimer-cta {
              display: inline;
            }
          }
        }
        .price-information-new {
          .price-information-new__price {
            font-family: $ted-next;
            .price-information-new__amount {
              font-weight: 700;
            }
            .price-information-new__divider,
            .price-information-new__term {
              font-weight: 700;
              font-size: 18px;
            }
            .price-information-new__term {
              padding-bottom: 1px;

              sup,
              sub {
                font-size: 0.65em;
              }
            }
          }

          &.price-information-new--is-promo {
            .price-information-new__price {
              grid-column-gap: 1px;
              .price-information-new__amount {
                font-size: 40px;
                font-weight: 700;
              }
              .price-information-new__term {
                font-weight: 700;
                padding-bottom: 0;
                padding-left: 0px;
                transform: translateY(-2px);
              }
              .price-information-new__price-details {
                .price-information-new__divider {
                  font-weight: 700;
                  font-size: 18px;
                  padding: 0 3px;
                  transform: translate(0, -2px);
                }
              }
            }
          }
        }

        .product-plan-card__details-toggle {
          font-size: 14px;
          color: map-get($rogers-colors, secondary-light);
          &:hover {
            color: map-get($rogers-colors, primary-light-hover);
            text-decoration: underline;
          }
        }
      }
    }
  }

  @include e(card-face) {
    display: grid;
    grid-template-rows: 30px 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 'recommended' 'card';

    border-top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    will-change: transform;
    transform-style: flat;
  }
  @include e(card) {
    background-color: white;
    box-shadow: 0px 0px 4px map-get($colors-overlay, shadow);
    grid-area: card;
    display: flex;
    flex-direction: column;
    max-width: 346px;
    min-width: 346px;
    @include media-breakpoint-down(md) {
      max-width: 310px;
      min-width: 310px;
    }
  }

  @include e(recommended) {
    grid-area: recommended;
    background-color: map-get($colors, quinary);
    color: white;
    max-width: 243px;
    min-width: 243px;
    margin: 0 auto;
    padding: 8px 0;
    text-align: center;
    font-size: 10px;
    font-family: $ted-next;
    font-weight: $demibold;
    // text-transform: uppercase;
    letter-spacing: 3px;
    span {
      display: inline-block;
      transform: translateY(2px);
    }
  }

  @include e(product-name) {
    background-color: black;
    color: white;
    min-height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 23px;
    .rich-text {
      @include h3black();
      text-align: left;
      line-height: 24px;
    }

    @include m(primary) {
      background-color: map-get($colors, primary);
      .product-plan-card__banner {
        background-color: map-get($colors-neutral, black);
      }
    }
    @include m(grey) {
      background-color: map-get($colors-neutral, soft);
      color: map-get($colors-neutral, black);
    }

    @include m(dark) {
      background-color: map-get($colors, dark);
      .horizontal-plan-card__banner {
        background-color: map-get($colors, primary);
      }
    }
  }
  @include e(banner) {
    background: transparent
      linear-gradient(
        90deg,
        map-get($colors-gradient, hero-start) 0%,
        map-get($colors-gradient, hero-end) 100%
      )
      0% 0% no-repeat padding-box;
    color: white;
    font-size: 10px;
    line-height: 11px;
    font-family: $ted-next;
    font-weight: $medium;
    // text-transform: uppercase;
    letter-spacing: 3px;
    min-height: 24px;
    display: flex;
    align-items: center;
    padding-left: 23px;
    padding-right: 10px;
    transform: translateX(-23px);
    margin-bottom: 10px;
    .rich-text {
      font-weight: 700;
      font-size: 10px;
      line-height: 11px;
      transform: translateY(1px);
    }
  }
  @include e(button-container) {
    text-align: center;
    margin-top: auto;
    margin-bottom: 15px;
    padding-top: 15px;
    padding-left: 25px;
    padding-right: 25px;
    .button {
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      padding-top: 7px;
    }
  }
  @include e(details-toggle) {
    grid-area: link;
    color: map-get($colors, primary);
    text-align: center;
    background: none;
    border: 0;
    outline: none;
    display: block;
    margin: 0 auto 15px auto;
    font-weight: bold;
    font-family: $ted-next;
    font-weight: $demibold;
    cursor: pointer;
    &:focus,
    &:active {
      outline: none;
    }
    &:hover {
      color: map-get($colors, secondary);
    }
  }

  @include e(more-details) {
    margin: 25px;
    font-size: 14px;
    line-height: 1.6em;
    .rich-text {
      display: flex;
      flex-direction: column;
    }

    hr {
      border: 0;
      border-top: 1px solid map-get($colors-neutral, light);
      height: 1px;
      margin-top: 15px;
      margin-bottom: 15px;
      clear: both;
    }

    p + p {
      margin-top: 10px;
    }

    a {
      margin: auto;
      font-weight: bold;
      justify-self: center;
      display: inline-block;
    }

    ol,
    ul {
      margin: 0;
      padding: 0;
      line-height: 2em;
      list-style: none;

      li {
        line-height: 2em;
        display: flex;

        &:before {
          content: '';
          display: inline-block;
          background: url(../SVG/checkmark.svg) no-repeat no-repeat left 10px;
          background-size: 50%;
          background-size: 50%;
          height: 25px;
          flex-basis: 30px;
          flex-grow: 0;
          flex-shrink: 0;

          .product-plan-card__scene--scotch & {
            background: url(../SVG/checkmark-dark.svg) no-repeat no-repeat left
              10px;
          }
        }

        span {
          display: block;
        }

        .c-disclaimer-cta {
          font-size: 0.7em;
        }
      }
    }
  }

  @include e(footer) {
    margin-top: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(40px, auto) 70px 40px;
    grid-template-areas:
      'price'
      'button'
      'link';

    @include media-breakpoint-down(md) {
      grid-template-rows: auto 62px 30px;
    }

    .product-plan-card__button-container {
      grid-area: button;
    }
    .product-plan-card__details-toggle {
      grid-area: link;
    }
  }
}
