// Appliciation mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';

// Component styles
.product-feature-item {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-areas: 'icon text';
  align-items: center;
  width: 100%;
  justify-items: flex-start;
  align-items: center;

  @include media-breakpoint-only(sm) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  svg {
    max-height: 28px;
    max-width: 28px;
  }

  &__icon {
    color: $color-azure;
    align-items: center;
    align-self: center;
    justify-self: center;
    flex-shrink: 0;
    display: flex;

    &::before {
      font-size: 36px !important;
    }
    &.icon.icon-Product_SmartWiFi {
      &:before {
        font-size: 32px !important;
      }
    }
    &.icon.icon-checkmark {
      &:before {
        font-size: 14px !important;
      }
    }

    &::after {
      width: 28px;
      line-height: 28px;
      text-align: center;
      display: block;

      @include media-breakpoint-up(md) {
        width: 40px;
        line-height: 40px;
      }
    }
  }

  &__description {
    grid-area: text;
    color: $color-oxford-blue;
    font-size: 16px;
    line-height: 1.38;
  }
}
