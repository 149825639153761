// Application mixins
@import 'style/globals/animations';
@import 'style/grid/grid';
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/base/type';

// Module styles
@include b(form-set) {
  margin: 0;
  padding: 0;
  border: none;

  @include m(inline) {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-areas: 'title radios';
    align-items: center;

    @include media-breakpoint-only(md) {
      .form-set__item {
        margin-bottom: auto;
      }
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
      grid-template-areas: 'title' 'radios';
    }

    .form-set__description {
      font-family: $ted-next;
      line-height: 1.6em;
      padding-top: 0;
      padding-bottom: 0;

      @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 30px;
      }
    }
  }

  @include e(row) {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media-breakpoint-only(sm) {
      gap: 0;
      padding: 0;
      width: 100%;
    }

    & + & {
      margin-top: 0px;
    }

    @include m(tight) {
      & + & {
        margin-top: 0px;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      // align-items: baseline;
    }
  }

  .msie &__item {
    flex-basis: auto;
  }

  @include e(item) {
    display: block;
    flex: 1;
    // padding-left: 15px;

    @include media-breakpoint-only(sm) {
      padding-left: 0;
    }

    @include media-breakpoint-up(md) {
      // & + & {
      //   margin-left: 20px;
      // }
    }

    @include m(empty) {
      @include media-breakpoint-only(sm) {
        display: none;
      }
    }
  }

  @include e('description') {
    font-family: $ted-next;
    color: $color-oxford-blue;
    padding: 30px 10px;
  }
}
