@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(product-feature-list) {
  padding-left: 22px;
  padding-right: 22px;
  list-style: none;
  padding: 0;
  margin: 0 15px;
  display: block;
  // width: 100%;

  @include e(item) {
    display: grid;
    grid-gap: 22px;
    grid-template-columns: 28px 1fr;
    grid-template-rows: minmax(36px, auto);
    grid-template-areas: 'icon copy';
    align-items: center;
    border-bottom: 1px solid #efefef;
    padding: 5px 10px;
    font-size: 14px;
    width: 100%;
    .c-disclaimer-cta {
      font-size: 0.7em;
    }
    .icon {
      grid-area: icon;
    }

    span {
      grid-area: copy;
    }
  }
}
