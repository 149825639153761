@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

@include b('chat-button') {
  border: 0;
  background: none;
  cursor: pointer;
  font-weight: 700;
  font-family: $ted-next;
  text-decoration: none;
  font-size: 18px;
  align-items: center;
  display: grid;
  grid-template-columns: minmax(50px, max-content) max-content 15px;
  grid-template-areas: 'icon text chevron';
  grid-template-rows: 1fr;
  padding: 10px 83px;

  @include media-breakpoint-down(md) {
    padding: 10px 34px;
    font-size: 14px;
  }
  @include media-breakpoint-down(sm) {
    border-right: 0 !important;
    padding: 10px 54px;
  }
  @include media-breakpoint-only(md) {
    @media screen and (orientation: portrait) {
      padding: 10px 20px;
      .button__text {
        padding-left: 10px;
      }
    }
  }

  @include e(text) {
    grid-area: text;
    padding-left: 23px;
    margin: 0;
    @include media-breakpoint-down(md) {
      padding-left: 23px;
    }

    &:hover {
      text-decoration: underline;
    }

    em.offline {
      display: block;
      font-size: 12px;
      font-style: normal;
      max-width: 165px;
      margin-top: 5px;
      line-height: 1.2em;
    }
  }

  svg {
    grid-area: icon;
    max-height: 44px;
  }

  &:after {
    content: '\e900';
    font-family: 'shaw-icons';
    grid-area: chevron;
    transform: translate(5px, 1px);
    font-size: 18px;
  }

  @include m(chat-available) {
    opacity: 1;
    pointer-events: all;
    text-align: left;
  }

  @include m(chat-unavailable) {
    opacity: 0.4;
    pointer-events: none;
    text-align: left;
  }
}
