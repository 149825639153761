// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';
@import 'app/components/IconBadge/IconBadge';

@include b(notification__container) {
  @include m(white) {
    background-color: white;
  }
  @include m(grey) {
    background-color: map-get($colors-neutral, soft);
  }
  @include m(transparent) {
    background-color: transparent;
  }
  & + .button-and-link {
    padding-top: 30px;
  }

  @include m(margin-bottom) {
    padding-bottom: 30px;
  }
  @include m(margin-top) {
    padding-top: 30px;
  }
  @include m(margin-both) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

// Module styles
@include b(notification) {
  background-color: map-get($colors-neutral, soft);
  padding: map-get($copy-spacing, md) map-get($copy-spacing, lg);
  margin: map-get($copy-spacing, lg) 0;
  width: 100%;
  max-width: 1164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  @include media-breakpoint-only(sm) {
    border-top: 1px solid map-get($colors-neutral, mediumlight);
    border-bottom: 1px solid map-get($colors-neutral, mediumlight);
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  @include e(title) {
    font-family: $ted-next;
    font-weight: $demibold;
    font-size: 14px;
    line-height: 1.6em;
    min-width: 170px;

    @include media-breakpoint-only(sm) {
      margin: map-get($copy-spacing, sm) 0;
    }
    @include media-breakpoint-only(lg) {
      min-width: 260px;
    }
  }
  @include e(description) {
    font-size: 14px;
    line-height: 1.6em;
    // background-color: map-get($colors-neutral, mediumlight);
    color: map-get($colors-scotch, body);
  }
  @include e(divider) {
    background-color: map-get($colors-neutral, mediumlight);
    width: 1px;
    align-self: stretch;
    margin: 0 map-get($copy-spacing, md);

    @include media-breakpoint-only(sm) {
      display: none;
    }
  }
}
