// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';

@include b(region-selector) {
  @include e(button-label) {
    display: inline-flex;
    margin-top: 2px;
  }
  @include e(location) {
    border: 0;
    background: transparent;
    text-align: right;
    margin-left: auto;
    margin-right: 15px;
    width: auto;
    cursor: pointer;
    font-size: 14px;
    color: map-get($colors, primary);
    font-family: $ted-next;
    padding: 0.5em 5px;
    svg {
      margin-right: 3px;
      display: inline-block;
      transform: translateY(2px);
    }

    &:hover {
      color: map-get($colors, secondary);
      text-decoration: underline;
    }

    .global-nav-menu__items--scotch & {
      outline: none !important;
      &:hover {
        color: map-get($rogers-colors, primary-light-hover);
      }
      svg {
        transform: translateY(-1px);
      }
    }

    @include media-breakpoint-up(md) {
      padding: 0.5em 5px;
    }

    @include media-breakpoint-up(lg) {
      li & {
        padding: 0;
        margin-right: 0;
      }
    }

    @include m(open) {
      font-weight: bold;
    }
  }
}
