@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

.white {
  .eyebrow,
  .simple-contact__title,
  .simple-contact__description,
  .simple-contact__links a {
    color: white;
    @include media-breakpoint-only(sm) {
      border-right: 0;
    }
  }
  .simple-contact__link-item {
    border-right: 1px solid rgba($color-white, 0.3);
    &:hover {
      text-decoration: none;
    }
  }
}
.black {
  .eyebrow,
  .simple-contact__title,
  .simple-contact__description,
  .simple-contact__links a {
    color: black;
    @include media-breakpoint-only(sm) {
      border-right: 0;
    }
  }
  .simple-contact__link-item {
    border-right: 1px solid rgba($color-black, 0.3);
    &:hover {
      color: black !important;
      text-decoration: none;
      span {
        color: black;
        &:after {
          color: black;
          text-decoration: none;
        }
      }
    }
  }
}
.defaults {
  padding-top: map-get($component-spacing, sm);
  padding-bottom: map-get($component-spacing, sm);
  @include media-breakpoint-up(md) {
    padding-top: map-get($component-spacing, md);
    padding-bottom: map-get($component-spacing, md);
  }
  @include media-breakpoint-only(lg) {
    padding-top: map-get($component-spacing, lg);
    padding-bottom: map-get($component-spacing, lg);
    min-height: 308px;
    display: flex;
    align-items: center;
  }
}

@include b('simple-contact') {
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    @extend .white;
    @extend .defaults;
  }
  @include m(primary) {
    background-color: map-get($colors, primary);
    @extend .white;
    @extend .defaults;
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
    @extend .defaults;
    @extend .white;
  }
  @include m(dark) {
    background-color: $color-dark-blue;
    @extend .defaults;
    @extend .white;
  }
  @include m(black) {
    background-color: black;
    @extend .defaults;
    @extend .white;
  }
  @include m(light-grey) {
    background-color: #ededed;
    @extend .defaults;
    @extend .black;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
    @extend .defaults;
    @extend .black;
  }
  @include m(white) {
    @extend .defaults;
    @extend .black;
    background-color: #fff;
  }
  @include m(transparent) {
    @extend .defaults;
    @extend .black;
  }

  @include m(scotch) {
    @extend .defaults;
    @extend .black;
    background-color: map-get($rogers-colors, background-light);
    .simple-contact__links {
      .button {
        outline: none;
        color: map-get($rogers-colors, body);
        &:focus,
        &:hover {
          outline: none;
          span {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @include e('content') {
    @include make-container();
    @include make-container-max-widths();

    @include media-breakpoint-down(md) {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      width: 290px;
    }
  }

  @include e('title') {
    @include h2();
    text-align: center;
    margin-bottom: 0.5em;
  }
  @include e('description') {
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    @include media-breakpoint-only(sm) {
      br {
        display: none;
      }
    }
  }

  @include e('links') {
    margin-top: 15px;
    padding-top: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-only(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    a {
      color: $color-white;
      font-weight: 700;
      text-decoration: none;
      font-size: 18px;
      align-items: center;
      display: grid;
      grid-template-columns: minmax(50px, max-content) max-content 15px;
      grid-template-areas: 'icon text chevron';
      grid-template-rows: 1fr;
      padding: 10px 83px;

      span.icon.icon-Nav_Call::before {
        font-size: 46px;
      }

      @include media-breakpoint-down(md) {
        padding: 10px 54px;
      }
      &.chat-available {
        opacity: 1;
        pointer-events: all;
        text-align: left;
      }
      &.chat-unavailable {
        opacity: 0.4;
        pointer-events: none;
        text-align: left;

        em.offline {
          display: block;
          font-size: 12px;
          font-style: normal;
          max-width: 165px;
          margin-top: 5px;
          line-height: 1.2em;
        }

        &:after {
          content: '';
        }
      }

      &:after {
        content: '\e900';
        font-family: 'shaw-icons';
        grid-area: chevron;
        transform: translate(5px, 1px);
      }

      @include media-breakpoint-only(md) {
        .button__text {
          font-size: 14px;
        }
      }
      @include media-breakpoint-only(sm) {
        width: 100%;
        border-right: 0;
        .button__text {
          text-align: left;
          font-size: 14px;
        }
      }

      svg {
        grid-area: icon;
        max-height: 44px;
      }
      .button__text {
        grid-area: text;
        padding-left: 27px;
        margin: 0;
        @include media-breakpoint-down(md) {
          padding-left: 23px;
        }
      }
      &:hover,
      &.button--link:hover {
        color: $color-white;
        .button__text {
          text-decoration: underline;
        }

        &:after {
          text-decoration: none;
        }
      }
    }

    &.cols-2 a {
      padding: 10px 83px;
      .button__text {
        padding-left: 27px;
      }

      @include media-breakpoint-down(md) {
        padding: 10px 54px;
        .button__text {
          padding-left: 23px;
        }
      }
    }

    &.cols-3 {
      a {
        padding: 10px 54px;
        .button__text {
          padding-left: 23px;
        }

        @include media-breakpoint-only(md) {
          @media screen and (orientation: portrait) {
            padding: 10px 20px;
            .button__text {
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
  @include e('link-item') {
    border-right: 1px solid rgba($color-white, 0.3);
    line-height: 1;
    &:last-child {
      border-right: 0;
    }

    @include media-breakpoint-only(sm) {
      border: 0;
      padding: 30px 15px !important;
      border-bottom: 1px solid rgba($color-white, 0.3);
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
