// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';

// Module styles
@include b(form-field) {
  width: 100%;

  @include e(input-label) {
    color: map-get($colors-neutral, dark);
    display: grid;
    grid-template-rows: 10px map-get($component-spacing, md) min-content;
    grid-template-areas: 'label' 'input' 'error';
    // margin: map-get($copy-spacing, sm);
    padding-bottom: 10px;
    width: 100%;
    input,
    textarea {
      outline: none;
      font-size: 18px;
      grid-area: input;
      border: 1px solid map-get($colors-neutral, light);
      padding: map-get($copy-spacing, sm);
      height: map-get($component-spacing, md);
      padding-left: 15px;
      font-family: $ted-next;
      border-radius: 3px;
    }
    textarea {
      min-height: 10em;
      position: relative;
      z-index: 2;
    }
    span.form-field__span {
      grid-area: input;
      align-self: center;
      padding-left: 15px;
      transition-timing-function: ease;
      transition: font-size 0.3s;
      font-size: 16px;
      position: relative;
      z-index: 3;
    }

    @include m(focus) {
      // focus styles
      grid-template-rows: 10px map-get($component-spacing, md) min-content;
      grid-template-areas: 'label' 'input' 'error';

      span.form-field__span {
        grid-area: label;
        font-size: 12px;
        transform: translateY(20px);
      }
      input,
      input:focus-visible,
      textarea,
      textarea:focus-visible {
        border: 1px solid map-get($rogers-colors, link-dark);
      }
      &.form-field__input-label--textarea {
        span {
          display: none;
        }
      }
    }
    @include m(filled) {
      // focus styles
      grid-template-rows: 10px map-get($component-spacing, md) min-content;
      grid-template-areas: 'label' 'input' 'error';

      span.form-field__span {
        grid-area: label;
        font-size: 12px;
        transform: translateY(20px);
      }
      &.form-field__input-label--textarea {
        span {
          display: none;
        }
      }
    }
    @include m(textarea) {
      min-height: 11.5em;
    }
  }

  @include e(select-label) {
    display: grid;
    grid-template-rows: 10px map-get($component-spacing, md) min-content;
    grid-template-areas: 'label' 'input' 'error';
    // margin: map-get($copy-spacing, sm);
    select:focus-visible {
      outline: 1px solid map-get($rogers-colors, link-dark);
    }
    select {
      color: map-get($colors-neutral, dark);
      font-family: $ted-next;
      font-size: 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 16px;
      grid-area: input;
      border: 1px solid map-get($colors-neutral, light);
      padding: map-get($copy-spacing, sm);
      height: map-get($component-spacing, md);
      padding-left: 15px;
      position: relative;
      z-index: 2;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSI4LjA3NCIgdmlld0JveD0iMCAwIDE0IDguMDc0Ij4KICA8ZyBpZD0iSWNvbnNfTWlzY19Eb3duQXJyb3ciIGRhdGEtbmFtZT0iSWNvbnMvTWlzYy9Eb3duQXJyb3ciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMS4wNzQpIj4KICAgIDxnIGlkPSJJY29uc19NaXNjX0Rvd25BcnJvdy0yIiBkYXRhLW5hbWU9Ikljb25zL01pc2MvRG93bkFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMikgcm90YXRlKDkwKSI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzg2NSIgZGF0YS1uYW1lPSJQYXRoIDg2NSIgZD0iTS4xMTYsMTEuODc3YS40NDUuNDQ1LDAsMCwxLDAtLjZMNS4wNTMsNi4wMDYuMTE2LjcyNGEuNDQ1LjQ0NSwwLDAsMSwwLS42LjM3OC4zNzgsMCwwLDEsLjU2LDBMNS44ODQsNS43QS40MjcuNDI3LDAsMCwxLDYsNmEuNDQ3LjQ0NywwLDAsMS0uMTE2LjNMLjY3NiwxMS44NjdBLjM3MS4zNzEsMCwwLDEsLjExNiwxMS44NzdaIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==');
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: 94% center;
      border-radius: 3px;
      option {
        font-size: 16px;
      }
    }
  }

  @include e(error) {
    grid-column: 1/-1;
    color: map-get($colors-alert, error);
    padding-top: map-get($copy-spacing, sm);
    font-size: 12px;
    grid-area: error;
  }

  @include e('tooltip') {
    position: relative;
    margin: 5px 0 0;
    display: block;
    color: map-get($colors-scotch, medium-grey);
    font-size: 13px;
    line-height: normal;
  }
}
