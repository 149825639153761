.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
}

.sticky.fixed {
  position: sticky;
}
