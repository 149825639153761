// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';

@include b(search-no-results) {
  background-color: $color-white;

  @include e(container) {
    min-height: 586px;

    @include media-breakpoint-only(md) {
      min-height: 432px;
    }

    @include media-breakpoint-only(lg) {
      min-height: 416px;
    }

    @include make-container();
    @include make-container-max-widths();
  }

  @include e(title) {
    font-family: $ted-next;
    font-size: 36px;
    font-weight: 100;
    line-height: 1.39;
    letter-spacing: -1px;
    color: $color-oxford-blue;
    padding: 30px 0;
    margin: 0;
  }

  @include e(description) {
    font-family: $ted-next;
    font-size: 20px;
    font-weight: 500;
    color: $color-oxford-blue;
    margin-bottom: 5px;
  }

  @include e(list) {
    padding-left: 16px;
    padding-bottom: 30px;
  }

  @include e(list-item) {
    font-size: 16px;
    line-height: 1.5;
    color: $color-bluish-grey;
  }
}
