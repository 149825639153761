@import 'style/globals/_colors';
@import 'style/grid/grid';

@import '~slick-carousel/slick/slick.css';

.carousel {
  $carousel-dot-inactive: $color-cloud;
  $carousel-dot-active: $color-azure;

  position: relative;
  max-width: 100%;

  &__slide {
    display: inline-block;
    width: 50%;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }

  &__dots {
    // Have to override style attribute
    display: flex !important; /* stylelint-disable-line declaration-no-important */
    flex-direction: row;
    justify-content: center;

    li {
      display: inline-block;
      border: 1px solid $carousel-dot-inactive;
      background-color: transparent;
      height: 13px;
      width: 13px;
      font-size: 0;
      border-radius: 9px;
      margin: 7px;

      &.slick-active {
        background-color: $carousel-dot-active;
        border: 0;
      }

      button {
        height: 100%;
        width: 100%;
        background: transparent;
        font-size: 0;
        border: 0;
      }
    }
  }

  .slick-list {
    // Have to override style attribute
    height: auto !important; /* stylelint-disable-line declaration-no-important */
    @include media-breakpoint-up(lg) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .slick-slide {
    vertical-align: top;
    outline: none;
  }

  .slider-arrow--disabled {
    pointer-events: none;
  }
}
