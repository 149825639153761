// Application mixins
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/base/type';

// Component styles

@include b(gradient-background) {
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    &.gradient-hero {
      background: linear-gradient(
        90deg,
        map-get($colors-gradient, hero-start),
        map-get($colors-gradient, hero-end)
      );
    }
  }
  @include m(shaw) {
    background: map-get($rogers-colors, body);
    &.gradient-hero {
      background: map-get($rogers-colors, body);
    }
  }
  @include m(scotch) {
    background: map-get($rogers-colors, body);
    &.gradient-hero {
      background: map-get($rogers-colors, body);
    }
  }
  @include m(red) {
    background: map-get($rogers-colors, primary-light);
    &.gradient-hero {
      background: map-get($rogers-colors, primary-light);
    }
  }

  @include m(black) {
    background: black;
    &.gradient-hero {
      background: black;
    }
  }
  @include m(dark) {
    background: map-get($rogers-colors, dark);
    &.gradient-hero {
      background: map-get($rogers-colors, dark);
    }
  }

  @include m(white) {
    background: white;
    &.gradient-hero {
      background: white;
      .hero__heading,
      .hero__subtitle,
      .hero__eyebrow {
        color: map-get($rogers-colors, body);
      }
    }
  }

}
