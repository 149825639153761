// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

// Module styles
.contact-card {
  &__title {
    color: $color-black;
    margin-top: 32px;
    text-align: center;
    @include h2();

    @include media-breakpoint-up(md) {
      margin-top: 12px;
      letter-spacing: -1px;
    }

    @include media-breakpoint-up(lg) {
      letter-spacing: 0;
    }
  }

  &__description {
    color: $color-body;
    font-size: 18px;
    margin-top: 11px;
    text-align: center;
    line-height: 1.39;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-only(sm) {
      @include make-col(10);
    }

    @include media-breakpoint-up(md) {
      margin-top: 8px;
      font-size: 16px;
      line-height: 1.56;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      font-size: 16px;
      line-height: 2.25;
    }
  }

  &__buttons {
    display: flex;
    align-items: stretch;
    margin: 20px auto 28px;
    flex-direction: column;
    flex-shrink: 0;

    @include media-breakpoint-up(md) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 38px;
      flex-direction: row;
      align-items: baseline;
      justify-content: center;
    }

    @include media-breakpoint-up(lg) {
      margin: 28px 40px 56px;
    }
  }

  &__button {
    padding: 30px;
    text-align: center;
    height: 100%;

    @include media-breakpoint-only(sm) {
      flex: 1;
    }

    & + & {
      @include media-breakpoint-only(sm) {
        border-top: $border-divider;
      }

      @include media-breakpoint-up(md) {
        border-left: $border-divider;
      }
    }

    @include media-breakpoint-up(md) {
      padding: 5px 0;

      &:first-child {
        margin-right: 25px;
      }

      &:nth-child(2) {
        margin-right: 25px;
        padding-left: 30px;
      }

      &:last-child {
        padding-left: 30px;
      }
    }
  }
}
