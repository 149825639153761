// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/icons';
@import 'style/grid/grid';
@import 'style/utils/bem';

@include b(search-pagination) {
  height: 90px;
  background-color: $color-pale-grey;
  display: flex;
  align-items: center;
  justify-content: center;

  @include e(link) {
    font-family: $ted-next;
    font-size: 16px;
    font-weight: bold;
    color: $color-azure;
    text-decoration: none;

    & + & {
      margin-left: 30px;
    }

    .no-touch &:hover {
      color: $color-azure-dark;
    }

    @include m(disabled) {
      color: gray;
      pointer-events: none;
      cursor: default;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 2px;
        background-color: $color-azure;
        bottom: -4px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
    }
  }

  @include e(ellipsis) {
    font-family: $ted-next;
    font-size: 16px;
    font-weight: bold;
    color: gray;
    margin: 0 30px;
  }

  @include e(arrow) {
    color: $color-azure;
    margin-right: 15%;
    transform: translate3d(4px, 0, 0) rotate3d(0, 1, 0, 180deg);
    font-size: 27px;

    .icon {
      font-weight: 800;
    }

    .no-touch &:hover {
      .icon.icon-#{map_get($icons, chevronBlue)} {
        &::before {
          color: $color-azure-dark;
        }
      }
    }

    @include media-breakpoint-up(md) {
      margin-right: 100px;
    }

    @include m(right) {
      transform: translate3d(-4px, 0, 0) rotate(0deg);
      margin-right: 0;
      margin-left: 15%;

      @include media-breakpoint-up(md) {
        margin-left: 100px;
      }
    }

    @include m(disabled) {
      color: gray;
      pointer-events: none;
      cursor: default;

      .icon.icon-#{map_get($icons, chevronBlue)} {
        &::before {
          color: gray;
        }
      }
    }
  }
}
