// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';

// Module styles
@include b(radio-group) {
  .form-set__item {
    @include media-breakpoint-up(md) {
      flex: auto;
      width: 25%;
      margin-left: 0;
      display: flex;
      align-items: center;

      .msie &,
      .msedge & {
        width: auto;
      }
    }
  }
  @include e(description) {
    font-size: 16px;
    margin: 15px 0;
    @include media-breakpoint-up(md) {
      margin: 15px;
    }
  }

  @include e(label) {
    color: map-get($colors-neutral, dark);
    padding: 10px;
    margin: 10px;
    max-width: 240px;
    display: grid;
    column-gap: 10px;
    grid-template-columns: 20px 1fr;
    grid-template-rows: max-content min-content;
    grid-template-areas:
      'radio label'
      'radio desc';
    cursor: pointer;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }

    input[type='radio'] {
      grid-area: radio;
      align-self: top;
      transform: translateY(2px);
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: var(--form-background);
      /* Not removed via appearance */
      margin: 0;

      font: inherit;
      color: map-get($colors, primary);
      width: 1.15em;
      height: 1.15em;
      border: 1px solid map-get($rogers-colors, link-dark);
      border-radius: 50%;
      transform: translateY(-0.075em);
      cursor: pointer;
      display: grid;
      place-content: center;
    }

    input[type='radio']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em map-get($rogers-colors, primary-light);
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }

    input[type='radio']:checked::before {
      transform: scale(1);
    }

    input[type='radio']:focus {
      outline: max(0.15em, 0.15em) solid $color-outline;
      outline-offset: max(0.3em, 0.3em);
    }

    span {
      grid-area: label;
    }
  }

  @include e(error) {
    color: #ff334c;
    display: block;
    font-size: 12px;
    margin-top: 6px;
    margin-left: 15px;
    opacity: 1;
    transition: all 0.25s ease;
  }
}
