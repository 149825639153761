// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: translateY(-50%) rotate(360deg);
  }
}

// Module styles
@include b(form-field) {
  position: relative;
  margin-top: 6px;

  @include e(loading) {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
    width: 16px;
    color: $color-primary;
    background: url('./reload.svg') no-repeat center;
    background-size: cover;
    animation: spin 4s linear infinite;
  }
  @include e(results) {
    position: absolute;
    z-index: 999;
    background: $color-white;
    display: flex;
    flex-direction: column;
    line-height: 1.8em;
    border-left: 1px solid $color-fog;
    border-right: 1px solid $color-fog;
    width: 100%;
    font-size: 14px;
    top: 75px;
  }

  @include e(suggestion-item) {
    padding: 5px 10px;
    cursor: pointer;
    border-bottom: 1px solid $color-fog;
  }
  @include e(suggestion-item-active) {
    padding: 5px 10px;
    cursor: pointer;
    background-color: $color-fog;
    border-bottom: 1px solid transparent;
  }

  select,
  label {
    cursor: pointer;
  }

  input,
  select,
  textarea,
  label {
    width: 100%;
    color: #32434e;
    border: none;
    outline: none;
    font-size: 16px;
    background: none;
    line-height: 1;
    caret-color: $color-azure;
  }

  @include media-breakpoint-only(sm) {
    label {
      font-size: 18px;
    }
  }

  @include e(masked-input) {
    @include m(without-value) {
      &:not(:focus)::placeholder {
        color: transparent;
      }
    }
  }

  & .form-field-group__field__dropdown-label {
    align-items: center;
    display: flex;
    top: -4px;
    height: 16px;

    .form-field__chevron {
      transform: rotate(90deg);
      &:before {
        font-size: 10px !important ;
      }
    }
  }

  @include e(input-label) {
    position: relative;
    color: map-get($colors-neutral, dark);
    display: grid;
    grid-template-rows: 10px map-get($component-spacing, md) min-content;
    grid-template-areas: 'label' 'input' 'error';
    // margin: map-get($copy-spacing, sm);
    padding-bottom: 10px;
    width: 100%;
    input {
      outline: none;
      font-size: 18px;
      grid-area: input;
      border: 1px solid map-get($colors-neutral, light);
      padding: map-get($copy-spacing, sm);
      height: map-get($component-spacing, md);
      padding-left: 15px;
      border-radius: 3px;
    }

    span.form-field__span {
      grid-area: input;
      align-self: center;
      padding-left: 15px;
      transition-timing-function: ease;
      transition: font-size 0.3s;
      font-size: 16px;
      position: relative;
      z-index: 3;
    }

    @include m(focus) {
      // focus styles
      grid-template-rows: 10px map-get($component-spacing, md) min-content;
      grid-template-areas: 'label' 'input' 'error';

      span.form-field__span {
        grid-area: label;
        font-size: 12px;
        transform: translateY(20px);
      }
      input,
      input:focus-visible,
      textarea,
      textarea:focus-visible {
        border: 1px solid map-get($rogers-colors, link-dark);
      }
      &.form-field__input-label--textarea {
        span {
          display: none;
        }
      }
    }
    @include m(filled) {
      // focus styles
      grid-template-rows: 10px map-get($component-spacing, md) min-content;
      grid-template-areas: 'label' 'input' 'error';

      span.form-field__span {
        grid-area: label;
        font-size: 12px;
        transform: translateY(20px);
      }
      &.form-field__input-label--textarea {
        span {
          display: none;
        }
      }
    }
  }
}
