// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/breakpoints';

// Component styles
.collapsable-drawer {
  position: relative;

  &__toggle {
    font-size: 18px;
    font-weight: 100;
    line-height: 2;
    letter-spacing: 0.1px;
    font-family: $ted-next;
    font-weight: $bold;
    border: 0;
    width: 100%;
    height: 70px;
    text-align: left;
    padding: 0 20px;
    background-color: $color-black;
    color: $color-white;
    border-bottom: solid 1px $color-white;
    cursor: pointer;

    &:focus {
      outline: 0;

      h3 {
        position: relative;
      }
    }

    &:active {
      outline: none;
    }

    @include media-breakpoint-up(md) {
      padding: 0 34px;
    }

    @include media-breakpoint-up(lg) {
      background-color: $color-transparent;
      border-bottom: none;
      height: auto;
      padding: 0;
      pointer-events: none;
    }

    &-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-family: $ted-next;
        font-weight: $demibold;
        font-size: 20px;
        line-height: 28px;
      }
    }

    &-icon {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    will-change: max-height;
    transition: max-height $default-timing;
    background-color: $color-white;
    color: $color-black;
    line-height: 2em;
    font-size: 16px;
    font-family: $ted-next;
    font-weight: $medium;
    padding-left: 34px;

    @include media-breakpoint-only(sm) {
      padding-right: 34px;
    }

    @include media-breakpoint-up(md) {
      padding-left: 34px;
    }

    @include media-breakpoint-up(lg) {
      max-height: none;
      background-color: $color-transparent;
      color: inherit;
      line-height: inherit;
      font-size: inherit;
      font-family: inherit;
      overflow: visible;
      padding-left: 0;
    }
  }

  &--expanded {
    .collapsable-drawer__content {
      max-height: 1000px;

      .footer__list-section-title {
        color: $color-black;
      }
    }
  }
}
