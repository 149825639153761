// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';

// Module styles
@include b(checkbox-group) {
  @include m(individual) {
    .form-set__row {
      flex-direction: column;
    }

    @include media-breakpoint-only(lg) {
      .form-set__row {
        flex-direction: row;
      }
    }

    .form-set__item {
      margin-left: 0;
      margin-bottom: 45px;

      &:last-child {
        margin-bottom: 8px;
      }
    }

    @include media-breakpoint-only(sm) {
      .form-set__item {
        margin-left: 0;
        margin-bottom: 37px;

        &:last-child {
          margin-top: 0;
          margin-bottom: 8px;
        }
      }
    }
  }

  @include e(error) {
    color: #ff334c;
    display: block;
    font-size: 12px;
    margin-top: 15px;
    margin-left: 15px;
    opacity: 1;
    transition: all 0.25s ease;
  }
}
