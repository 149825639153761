.image-switcher {
  position: relative;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &--selected {
      opacity: 1;
    }
  }
}
