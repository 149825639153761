// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

// Component styles
.basic-icon-box {
  text-align: center;
  padding-top: 38px;
  padding-bottom: 30px;
  display: grid;
  row-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(20px, max-content) repeat(4, min-content);
  grid-template-areas: 'icon' 'eyebrow' 'title' 'desc' 'button' 'link';
  min-height: 180px;

  @include media-breakpoint-down(md) {
    justify-content: center;
  }

  &__icon {
    grid-area: icon;
    position: relative;
    color: $color-primary;
    min-height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__eyebrow {
    grid-area: eyebrow;
  }
  &__title {
    grid-area: title;
    color: $color-oxford-blue;
    @include h3();
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
  }

  .modal & {
    &__icon,
    &__title {
      margin-bottom: 15px;
    }
  }

  &__description {
    grid-area: desc;
    color: $color-bluish-grey;
    display: block;
    font-size: 18px;
    line-height: 1.5;
    padding-bottom: 0;
    padding-top: 10px;

    + .button {
      margin-top: map-get($copy-spacing, md);
      width: auto;
      flex: none;
    }

    @include media-breakpoint-up(md) {
      font-size: 16px;
      letter-spacing: 0.2px;
    }

    @include media-breakpoint-only(lg) {
      min-height: 72px;
      // max-height: 72px;
    }
  }

  &__cta-button {
    grid-area: button;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;

    &.button--secondary {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__cta-link {
    grid-area: link;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    justify-self: flex-end;

    img {
      margin: 0 7px;
    }
  }

  @include media-breakpoint-only(md) {
    padding-top: 48px;
    padding-bottom: 40px;
  }

  @include media-breakpoint-only(lg) {
    padding-top: 8px;
    padding-bottom: 0;
  }
}
