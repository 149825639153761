// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

// Module styles
@include b('image-text-box') {
  background-color: transparent;

  @include m(primary) {
    background-color: map-get($colors, primary);
  }
  @include m(gradient) {
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
  }
  @include m(secondary) {
    background-color: map-get($colors, secondary);
  }
  @include m(dark) {
    background-color: $color-dark-blue;
  }
  @include m(black) {
    background-color: black;
  }

  @include m(light-grey) {
    background-color: #ededed;
  }
  @include m(soft-white) {
    background-color: #f6f6f6;
  }
  @include m(white) {
    background-color: #fff;
  }

  @include e('container') {
    @include make-container();
    @include make-container-max-widths();
    margin-top: map-get($component-spacing, sm);
    margin-bottom: map-get($component-spacing, sm);

    @include media-breakpoint-up(md) {
      margin-top: map-get($component-spacing, md);
      margin-bottom: map-get($component-spacing, md);
    }

    @include media-breakpoint-up(lg) {
      margin-top: map-get($component-spacing, lg);
      margin-bottom: map-get($component-spacing, lg);
    }

    @include media-breakpoint-only(sm) {
      flex-direction: column;
      box-shadow: none;
    }
  }
  @include e('content-box') {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.13);
    background-color: white;
    border-radius: 3px;
    display: flex;
    position: relative;

    @include media-breakpoint-only(sm) {
      flex-direction: column;
    }
  }

  @include e('image') {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    align-self: stretch;
    flex: 1;

    @include media-breakpoint-only(sm) {
      background-size: cover;
      min-height: 290px;
      width: 100%;
      margin: 0;
    }
  }

  @include e('content') {
    padding: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: white;
    flex: 1;

    @include media-breakpoint-only(sm) {
      background: transparent;
      align-items: center;
    }
  }

  @include e('feature') {
    font-family: $ted-next;
    font-weight: $demibold;
    font-size: 26px;
    line-height: 0.93;
    color: $color-oxford-blue;
    margin-bottom: 7px;

    @include media-breakpoint-only(sm) {
      text-align: center;
      font-size: 24px;
    }
  }

  @include e('title') {
    font-family: $ted-next;
    font-weight: $demibold;
    font-size: 24px;
    line-height: 32px;
    color: $color-oxford-blue;
    margin-bottom: 20px;

    @include media-breakpoint-only(sm) {
      text-align: center;
    }
  }

  @include e('description') {
    font-family: $ted-next;
    font-weight: $medium;
    font-size: 16px;
    line-height: 1.44;
    color: $color-bluish-grey;
    margin-bottom: 20px;

    @include media-breakpoint-only(sm) {
      text-align: center;
    }
  }
}
