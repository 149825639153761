// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/grid/breakpoints';
@import 'style/utils/bem';

// Component styles
@include b(product-offer-add-on-card) {
  @include make-container();
  @include make-container-max-widths();

  @include media-breakpoint-only(sm) {
    .card__container {
      padding-bottom: 52px;
    }
  }

  @include media-breakpoint-only(md) {
    .card__container {
      padding-bottom: 48px;
    }
  }

  @include media-breakpoint-only(lg) {
    .basic-text-box {
      min-width: 300px;
    }
    .card__container {
      padding-bottom: 50px;
    }
  }

  .card__content {
    padding: 0 47px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-only(sm) {
      padding: 0 22px;
    }
  }

  .basic-text-box {
    @include media-breakpoint-only(sm) {
      margin-bottom: 45px;
      max-width: 290px;
    }

    @include media-breakpoint-only(md) {
      margin-bottom: 40px;
      max-width: 406px;
    }

    &__title {
      margin-bottom: 12px;
    }

    &__description {
      color: $color-bluish-grey;
    }
  }

  @include e(title) {
    text-align: center;
    color: $color-oxford-blue;
    margin: 53px 0 0;
    @include h2();

    @include media-breakpoint-only(md) {
      margin: 36px 0 0;
    }

    @include media-breakpoint-only(lg) {
      margin: 44px 0 0;
    }
  }

  @include e(text-box-container) {
    margin: 32px 0 0;

    @include media-breakpoint-only(md) {
      margin: 47px 0 0;
    }

    @include media-breakpoint-only(lg) {
      margin: 58px 0 0;

      .msie &,
      .msedge & {
        width: 100%;
      }
    }
  }

  @include e(button-container) {
    margin-top: 54px;
    position: relative;
    max-width: 100%;

    @include media-breakpoint-only(md) {
      margin-top: 54px;
    }

    @include media-breakpoint-only(lg) {
      margin-top: 53px;
    }
  }
}
