// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(gradient-hero) {
  .hero__eyebrow {
    margin-bottom: 0;
  }
  .hero {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 300px;
  }

  .hero__hero-body {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .hero__heading {
    display: flex;
    gap:20px;
    justify-content: center;
    align-items: center;
    color: $color-white;

    @include media-breakpoint-only(lg) {
      min-width: 600px;
    }
  }

  .hero__eyebrow {
    color: $color-white;
  }

  @include m('with-breadcrumbs') {
    padding-bottom: 60px;
    padding-top: 0px;
    position: relative;
    z-index: 1;
    .hero {
      min-height: 0;
      flex-direction: column;

      .hero__heading {
        margin: 0 auto;
        font-size: 40px;
      }
    }
  }
}
