// Application mixins
@import 'app/components/IconBadge/IconBadge';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/utils/bem';

// Component styles
.collapsable-card {
  position: relative;
  display: flex;
  flex-direction: column;
  // flex: 1 1 auto;
  min-width: 0;

  @include m(scotch) {
    .collapsable-card__toggle {
      .collapsable-card__icon-container {
        .chevron.chevron--blueChevron {
          &:before {
            background-color: map-get($rogers-colors, body);
          }
          &:after {
            background-color: map-get($rogers-colors, body);
          }
        }
      }

      &:hover {
        background: map-get($rogers-colors, primary-light-hover) !important;
        .collapsable-card__icon-container {
          .chevron.chevron--blueChevron {
            &:before {
              background-color: map-get($rogers-colors, white) !important;
            }
            &:after {
              background-color: map-get($rogers-colors, white) !important;
            }
          }
        }
      }
    }
  }
  @include e(container) {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    margin: 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    @include media-breakpoint-up(md) {
      // flex: 1 1 auto;
      // margin: 0 -15px;
    }
  }

  @include e(toggle-container) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @include e(toggle) {
    display: none;
    position: relative;
    transform: translate3d(0, 25px, 0);
    cursor: pointer;
    z-index: 1;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    border: none;
    background-color: $color-pale-grey;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    &:hover {
      background: linear-gradient(
        90deg,
        map-get($colors-gradient, hover-start),
        map-get($colors-gradient, hover-end)
      );
      .chevron {
        &:before,
        &:after {
          background-color: white !important;
        }
      }
    }
  }

  @include e(icon-container) {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include e(main-content) {
    display: flex;
    position: relative;
  }

  @include e(overflow-content) {
    display: flex;
    align-items: baseline;
    flex: 1 1 auto;
    max-height: 0;
    will-change: max-height;
    opacity: 0;
    overflow: hidden;
    transition:
      max-height 500ms ease-in-out,
      opacity 500ms ease-in-out;

    & > div {
      width: 100%;
    }
  }

  @include m(collapsable) {
    @include e(toggle) {
      display: flex;
    }
  }

  @include m(open) {
    @include e(toggle) {
      .icon-badge {
        $chevron-top-flipped: 18px;

        &::before {
          top: $chevron-top-flipped;
          transform: rotate(-45deg);
        }

        &::after {
          top: $chevron-top-flipped;
          transform: rotate(45deg);
        }
      }
      //.icon {
      //  transform: rotateY(180deg);
      //}
    }
  }

  .collapsable {
    // TODO: revisit later.  hack to get the div created by ReactHeight to expand to full width.
    & > * {
      width: 100%;
    }
  }
}
