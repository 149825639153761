@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(product-spec-list) {
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(33%, auto));
  grid-auto-flow: dense;
  padding: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 0;
  // border-bottom: 1px solid $color-pale-grey;
  width: 100%;
  @include m(four) {
    grid-template-columns: repeat(auto-fit, minmax(50%, auto));
  }

  .product-spec-list__item + .product-spec-list__item {
    border-left: 1px solid $color-pale-grey;
  }

  @include e(item) {
    display: grid;
    grid-column-gap: 3px;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, minmax(max-content, auto));
    grid-template-areas: 'icon icon' 'number unit' 'description description';
    justify-content: center;
    text-align: center;
    background-color: white;

    &:nth-of-type(3n + 1) {
      border-left-color: transparent;
    }

    @include media-breakpoint-down(md) {
      padding-left: 7px;
      padding-right: 7px;
    }
  }

  @include e(icon) {
    grid-area: icon;
    padding-bottom: 5px;
    svg {
      max-width: 30px;
      max-height: 30px;
    }
  }
  @include e(item-container) {
    padding-left: 5px;
    padding-right: 5px;
  }
  @include e(item-link) {
    display: block;
    &:hover {
      color: $color-primary;
    }
  }
  @include e(item-number) {
    grid-area: number;
    font-weight: bold;
    font-size: 14px;
  }
  @include e(item-unit) {
    grid-area: unit;
    font-weight: bold;
    font-size: 14px;
    @include media-breakpoint-down(md) {
      p {
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
      }
    }
  }
  @include e(item-description) {
    grid-area: description;
    font-size: 10px;
  }
  @include m(scotch) {
    .product-spec-list__item-link.link {
      &:hover {
        color: map-get($rogers-colors, primary-light-hover);
        .product-spec-list__item-unit {
          text-decoration: underline !important;
        }
      }
    }
  }
}
