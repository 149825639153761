@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(price-information) {
  $square-size: 32px;

  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include e(limited-offer-flag-block) {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  @include e(limited-offer-flag-container) {
    position: relative;
    padding-top: 2px;
    width: 80%;
    max-width: 315px;
    margin: auto;
  }

  @include e(limited-offer-text) {
    font-family: $ted-next;
    font-weight: $medium;
    font-size: 16px;
    letter-spacing: 0.1px;
    text-align: center;
    color: $color-pale-grey;
    text-transform: uppercase;
    width: 100%;
    .c-disclaimer-cta {
      display: inline;
      top: -0.6em;
    }
  }

  @include e(limited-offer-flag) {
    position: relative;
    height: $square-size;
    padding: 0 16px;
    background: linear-gradient(
      90deg,
      map-get($colors-gradient, hero-start),
      map-get($colors-gradient, hero-end)
    );
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
  }

  @include e(divider) {
    width: 2px;
    height: 58px;
    background-color: #a5bbc5;
    opacity: 0.2;
  }

  @include e(price-wrapper) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 300px;
    width: 100%;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;

    @include m(1-prices) {
      .rich-text.price-information__price-block__text-below {
        min-width: 280px;
        .c-disclaimer-cta {
          display: inline;
          top: -0.6em;
        }
      }
    }
    @include m(2-prices) {
      width: 80%;
    }

    @include m(3-prices) {
      justify-content: space-between;

      .price-information__price-block:not(:first-child) {
        position: relative;
      }
    }
  }

  @include e(price-block) {
    @include price-bug();

    width: 90px;
    height: 100%;
    margin-bottom: 0;
  }

  @include e(new-customer-block) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include e(new-customer) {
    font-family: $ted-next;
    font-weight: $medium;
    font-size: 14px;
    line-height: 1.33;
    letter-spacing: -0.2px;
    text-align: center;
    color: $color-bluish-grey;

    .c-disclaimer-cta {
      font-size: 0.78em;
    }
  }

  @include e(starting-at-block) {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include e(starting-at) {
    margin-bottom: 10px;
    font-family: $ted-next;
    font-weight: $medium;
    font-size: 14px;
    line-height: 1.33;
    letter-spacing: -0.2px;
    text-align: center;
    color: $color-oxford-blue-dark;
  }

  @include m(is-bundle) {
    .price-information__new-customer-block {
      height: auto;
    }

    .price-information__new-customer {
      margin-top: 0;
    }
  }
}
