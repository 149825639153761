// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Module styles
@include b(basic-text-box-stack) {
  $horizontalSpacing: 80px;
  $horizontalSpacingDividers: 100px;

  display: flex;
  flex: 1;

  // basic-text-box styling (column is base orientation)
  .basic-text-box {
    position: relative;
    margin-bottom: 47px;
    max-width: 275px;
    text-align: center;
    @include media-breakpoint-only(sm) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 50px;
      text-align: left;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 60px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include e(content) {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;

    .basic-text-box {
      max-width: 100%;

      .basic-text-box__title {
        text-transform: none;
      }
    }

    @include media-breakpoint-only(md) {
      .basic-text-box {
        max-width: none;
      }
    }

    @include media-breakpoint-only(sm) {
      align-items: center;
      text-align: center;

      &.dividers {
        &::before {
          display: none;
        }
      }
    }

    @include m(row) {
      @include media-breakpoint-up(lg) {
        width: 100%;
        display: grid;
        grid-auto-columns: fit-content(33%);
        grid-template-areas: 'box box box';
        grid-template-rows: 1fr;
        justify-content: center;
      }

      .basic-text-box {
        text-align: center;
        display: grid;

        @include media-breakpoint-up(lg) {
          margin: 0;
        }

        .basic-text-box__title {
          font-weight: 700;
        }
      }
    }

    @include m(grid) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      justify-items: center;
      grid-gap: 80px;
      grid-row-gap: 10px;
      max-width: 650px;

      .basic-text-box {
        margin-right: 0;
        margin-bottom: 50px;

        &:nth-child(n + 3) {
          margin-bottom: 0;
        }
      }

      @include media-breakpoint-only(sm) {
        grid-template-columns: 1fr;
        justify-content: center;
        grid-gap: 20px;

        .basic-text-box {
          margin: auto;
          &:nth-child(n + 3) {
            margin-bottom: 50px;
          }
        }
      }

      .msie &,
      .msedge & {
        @include media-breakpoint-only(sm) {
          flex-direction: column;
        }
      }
    }

    @include m(dividers) {
      @include media-breakpoint-up(lg) {
        .basic-text-box {
          margin-right: calc(#{$horizontalSpacingDividers} / 2.5);
          margin-left: calc(#{$horizontalSpacingDividers} / 2.5);

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: calc(#{$horizontalSpacingDividers} / -2);
            width: 1px;
            background-color: $color-geyser;
          }

          &:last-of-type {
            &:before {
              content: '';
              display: none;
            }
          }
        }
      }
    }
  }

  @include e(buttons) {
    margin-top: 15px;
  }
  @include m(white) {
    background: $color-white;
  }
}
