// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';
@import 'style/grid/breakpoints';

$underline-width: 3px;

@include b('sticky-nav') {
  @include e('sticky-nav-body') {
    width: 100%;
    height: 91px;
    background-color: $color-white;

    @include m('color-pale-grey') {
      background-color: $color-pale-grey;
    }
  }

  @include e('items') {
    @include make-container;
    @include make-container-max-widths();

    @include m('narrow') {
      width: 720px;

      @include media-breakpoint-only(md) {
        width: 520px;
      }
    }

    overflow: hidden;

    ul {
      list-style-type: none;
      padding: 0;
      white-space: nowrap;
      display: flex;
      justify-content: center;

      > li {
        margin-left: 30px;
        margin-right: 30px;
      }
    }

    @include media-breakpoint-down(sm) {
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .msie &,
      .msedge & {
        -ms-overflow-style: none;
      }

      .firefox & {
        overflow: -moz-scrollbars-none;
      }

      ul {
        display: block;
      }

      ul > li {
        padding-right: 30px;
      }
    }
  }

  @include e('item') {
    display: inline-block;
    vertical-align: top;

    a {
      color: $color-oxford-blue;
      font-family: $ted-next;
      font-size: 1.2em;
      font-weight: 500;
      display: block;
      padding: 3px;
      margin: 30px 0;
      text-decoration: none;
    }

    a:focus,
    a:hover,
    a:active,
    a:visited {
      color: $color-oxford-blue;
      text-decoration: none;
      border-bottom: solid $underline-width transparent;
    }

    a:hover {
      border-bottom: solid $underline-width $color-primary;
    }

    @include m('active') {
      a,
      a:focus,
      a:active,
      a:visited {
        border-bottom: solid $underline-width $color-primary;
      }
    }
  }
}
