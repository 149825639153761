@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/grid/grid';
@import 'style/base/type';

@include b(slider-arrow) {
  border: 0;
  background: transparent;
  font-size: 0;
  position: absolute;
  z-index: 99999;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  .icon::before {
    transition: 0.2s color ease-in-out;
    font-size: 26px;
  }

  .icon {
    transform: scale(1);
    transition: 0.2s transform ease-in;
  }

  &:hover {
    .icon {
      transform: scale(1.05);
    }

    .icon::before {
      color: $color-azure-dark;
    }
  }

  @include m(previous) {
    left: -7px;

    .icon {
      transform: scale(1) rotate(180deg);
    }

    &:hover .icon {
      transform: scale(1.05) rotate(180deg);
    }
  }

  @include m(next) {
    right: -7px;
  }

  @include m(disabled) {
    cursor: default;

    &:focus {
      outline: none;
    }

    &.slider-arrow--previous .icon::before,
    &.slider-arrow--next .icon::before {
      color: $color-fog-dark;
    }
  }
}
