@font-face {
  font-display: swap;
  font-family: 'shaw-icons';
  src: url('/assets/fonts/shaw-icons.ttf?k0o3gq') format('truetype'),
    url('/assets/fonts/shaw-icons.woff?k0o3gq') format('woff'),
    url('/assets/fonts/shaw-icons.svg?k0o3gq#shaw-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'shaw-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ProductFeature_BlissEmployees:before {
  content: '\e94d';
  color: #da291c;
}
.icon-ProductFeature_BlissFlavours:before {
  content: '\e94e';
  color: #da291c;
}
.icon-ProductFeature_BlissStores:before {
  content: '\e94f';
  color: #da291c;
}
.icon-ProductFeature_InfluencerVideos:before {
  content: '\e950';
  color: #da291c;
}
.icon-ProductFeature_JusuEmployees:before {
  content: '\e951';
  color: #da291c;
}
.icon-ProductFeature_JusuLocations:before {
  content: '\e952';
  color: #da291c;
}
.icon-ProductFeature_JusuOrganic:before {
  content: '\e953';
  color: #da291c;
}
.icon-ProductFeatures_CentralizedManagement:before {
  content: '\e914';
  color: #da291c;
}
.icon-Product_PRITrunking:before {
  content: '\e912';
  color: #da291c;
}
.icon-Product_SIPTrunking:before {
  content: '\e913';
  color: #da291c;
}
.icon-ProductFeatures_IntegratedSecurity:before {
  content: '\e915';
  color: #da291c;
}
.icon-ProductFeatures_IntelligentPath:before {
  content: '\e916';
  color: #da291c;
}
.icon-ProductFeatures_SeamlessFailover:before {
  content: '\e917';
  color: #da291c;
}
.icon-email:before {
  content: '\e90b';
  color: #da291c;
}
.icon-left_quote:before {
  content: '\e90c';
  color: #da291c;
}
.icon-mail:before {
  content: '\e90d';
  color: #da291c;
}
.icon-phone:before {
  content: '\e90f';
}
.icon-right_quote:before {
  content: '\e910';
  color: #da291c;
}
.icon-all_weather:before {
  content: '\e908';
  color: #da291c;
}
.icon-smart_surveillance:before {
  content: '\e909';
  color: #da291c;
}
.icon-wrench:before {
  content: '\e90a';
  color: #da291c;
}
.icon-Nav_Search:before {
  content: '\e907';
}
.icon-close:before {
  content: '\e91e';
}
.icon-checkmark:before {
  content: '\e91b';
}
.icon-Product_Receptionist:before {
  content: '\e904';
  color: #da291c;
}
.icon-Product_AutoAttendant:before {
  content: '\e905';
  color: #da291c;
}
.icon-Product_MultiConfPorts:before {
  content: '\e906';
  color: #da291c;
}
.icon-ProductFeatures_Channels:before {
  content: '\e901';
  color: #da291c;
}
.icon-Nav_Call:before {
  content: '\e903';
}
.icon-Global_Youtube:before {
  content: '\e90e';
  color: #fff;
}
.icon-Link_ChevronWhite:before {
  content: '\e900';
  color: #fff;
}
.icon-Global_Instagram:before {
  content: '\e91f';
  color: #fff;
}
.icon-Global_LinkedIn:before {
  content: '\e920';
  color: #fff;
}
.icon-Nav_Chat:before {
  content: '\e923';
}
.icon-Link_Alert:before {
  content: '\e924';
  color: #fff;
}
.icon-Link_Error:before {
  content: '\e925';
  color: #ef0050;
}
.icon-Link_Offsite:before {
  content: '\e926';
  color: #da291c;
}
.icon-Link_StartOver:before {
  content: '\e927';
  color: #fff;
}
.icon-Global_GetInTouch:before {
  content: '\e92b';
  color: #da291c;
}
.icon-Global_LeadGen-Confirm:before {
  content: '\e92c';
  color: #da291c;
}
.icon-Global_ResourceLib:before {
  content: '\e92d';
  color: #da291c;
}
.icon-Global_Support-52:before {
  content: '\e92e';
  color: #da291c;
}
.icon-Link_ChevronBlue:before {
  content: '\e92f';
  color: #da291c;
}
.icon-ProductFeatures_Shaw2Shaw:before {
  content: '\e932';
  color: #da291c;
}
.icon-ProductFeatures_SimultaneousRing:before {
  content: '\e933';
  color: #da291c;
}
.icon-ProductFeatures_Transfer:before {
  content: '\e934';
  color: #da291c;
}
.icon-ProductFeatures_Voicemail2Email:before {
  content: '\e935';
  color: #da291c;
}
.icon-ProductFeatures_HandsetPhone:before {
  content: '\e936';
  color: #da291c;
}
.icon-ProductFeatures_News:before {
  content: '\e937';
  color: #da291c;
}
.icon-ProductFeatures_Sports-44:before {
  content: '\e938';
  color: #da291c;
}
.icon-ProductFeatures_TVMovies:before {
  content: '\e939';
  color: #da291c;
}
.icon-ProductFeatures_AutoUpdates:before {
  content: '\e93a';
  color: #da291c;
}
.icon-ProductFeatures_CustomerFocus:before {
  content: '\e93b';
  color: #da291c;
}
.icon-ProductFeatures_SafeBrowsing:before {
  content: '\e93c';
  color: #da291c;
}
.icon-ProductFeatures_Site2Site:before {
  content: '\e93d';
  color: #da291c;
}
.icon-ProductFeatures_Coverage:before {
  content: '\e93e';
  color: #da291c;
}
.icon-ProductFeatures_Firewall:before {
  content: '\e93f';
  color: #da291c;
}
.icon-ProductFeatures_ShawGo:before {
  content: '\e940';
  color: #da291c;
}
.icon-ProductFeatures_UnlimitedData:before {
  content: '\e941';
  color: #da291c;
}
.icon-ProductFeatures_UpgradeUpdate:before {
  content: '\e942';
  color: #da291c;
}
.icon-ProductFeatures_CallingFeatures:before {
  content: '\e943';
  color: #da291c;
}
.icon-ProductFeatures_HighBandwidth:before {
  content: '\e944';
  color: #da291c;
}
.icon-ProductFeatures_OptionalLongDistance:before {
  content: '\e945';
  color: #da291c;
}
.icon-ProductFeatures_Support-31:before {
  content: '\e946';
  color: #da291c;
}
.icon-ProductFeatures_Collaborative:before {
  content: '\e947';
  color: #da291c;
}
.icon-ProductFeatures_Competitive:before {
  content: '\e948';
  color: #da291c;
}
.icon-ProductFeatures_MultiDevice:before {
  content: '\e949';
  color: #da291c;
}
.icon-ProductFeatures_Music:before {
  content: '\e94a';
  color: #da291c;
}
.icon-ProductFeatures_Networks:before {
  content: '\e94b';
  color: #da291c;
}
.icon-ProductFeatures_Fast:before {
  content: '\e957';
  color: #da291c;
}
.icon-ProductFeatures_HDbox:before {
  content: '\e958';
  color: #da291c;
}
.icon-ProductFeatures_Mobile:before {
  content: '\e959';
  color: #da291c;
}
.icon-ProductFeatures_Analytics:before {
  content: '\e95a';
  color: #da291c;
}
.icon-ProductFeatures_Cloud:before {
  content: '\e95b';
  color: #da291c;
}
.icon-ProductFeatures_ComparePlans:before {
  content: '\e95c';
  color: #da291c;
}
.icon-ProductFeatures_DownloadPDF:before {
  content: '\e95d';
  color: #da291c;
}
.icon-ProductFeatures_MajorNetworkProgramming:before {
  content: '\e95e';
  color: #da291c;
}
.icon-Product_AsiaLongDistance:before {
  content: '\e95f';
  color: #da291c;
}
.icon-Product_BusinessPhone:before {
  content: '\e960';
  color: #da291c;
}
.icon-Product_InternationalCalls:before {
  content: '\e961';
  color: #da291c;
}
.icon-Product_SmartVoice:before {
  content: '\e962';
  color: #da291c;
}
.icon-Product_SmartVoiceApp:before {
  content: '\e963';
  color: #da291c;
}
.icon-Product_MultiCultural:before {
  content: '\e964';
  color: #da291c;
}
.icon-Product_Sports-10:before {
  content: '\e965';
  color: #da291c;
}
.icon-Product_ThemePacks:before {
  content: '\e966';
  color: #da291c;
}
.icon-Product_AntiVirus:before {
  content: '\e967';
  color: #da291c;
}
.icon-Product_BusinessTV:before {
  content: '\e968';
  color: #da291c;
}
.icon-Product_SmartSecurity:before {
  content: '\e969';
  color: #da291c;
}
.icon-Product_StaticIP:before {
  content: '\e96a';
  color: #da291c;
}
.icon-Product_WebHosting:before {
  content: '\e96b';
  color: #da291c;
}
.icon-Product_BusinessInternet:before {
  content: '\e96c';
  color: #da291c;
}
.icon-Product_SmartWiFi:before {
  content: '\e96d';
  color: #da291c;
}
.icon-Product_CustomizedSolutions:before {
  content: '\e931';
  color: #da291c;
}
.icon-Product_247Support:before {
  content: '\e94c';
  color: #da291c;
}
.icon-Product_VoiceSolutions:before {
  content: '\e918';
  color: #da291c;
}
.icon-Product_Video:before {
  content: '\e919';
  color: #da291c;
}
.icon-Product_Fiber:before {
  content: '\e91a';
  color: #da291c;
}
.icon-Product_Fast:before {
  content: '\e91c';
  color: #da291c;
}
.icon-Product_DataConnectivity:before {
  content: '\e91d';
  color: #da291c;
}
.icon-Product_CloudServices:before {
  content: '\e921';
  color: #da291c;
}
.icon-ProductFeatures_Virtualization:before {
  content: '\e922';
  color: #da291c;
}
.icon-ProductFeatures_Lock:before {
  content: '\e928';
  color: #da291c;
}
.icon-ProductFeatures_Clock:before {
  content: '\e929';
  color: #da291c;
}
.icon-ProductFeatures_Business:before {
  content: '\e92a';
  color: #da291c;
}
.icon-ProductFeatures_24-7Support:before {
  content: '\e930';
  color: #da291c;
}
.icon-billing:before {
  content: '\e911';
  color: #da291c;
}
.icon-search:before {
  content: '\e902';
}

.button--scotch-primary .icon-Link_Offsite:before {
  color: #ffffff;
}
