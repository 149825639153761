// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Component styles
@include b(testimonial) {
  padding: map-get($component-spacing, sm) 0;
  @include media-breakpoint-up(md) {
    padding: map-get($component-spacing, md) 0;
  }
  @include media-breakpoint-up(lg) {
    padding: map-get($component-spacing, lg) 0;
  }

  @include e(content) {
    @include make-container();
    @include make-container-max-widths();
  }
  @include e(quote-container) {
    max-width: 86%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    @include media-breakpoint-only(sm) {
      max-width: 100%;
    }
  }
  @include e(quote) {
    font-family: $ted-next;
    font-weight: 700;
    font-size: 22px;
    line-height: 42px;
    text-align: center;

    @supports (display: grid) {
      position: relative;
      display: grid;
      gap: 5px;
      grid-template-columns: minmax(0, 50px) 1fr minmax(0, 50px);
      grid-template-rows: minmax(0, 1fr);
      grid-template-areas: 'left quote right';
      @include media-breakpoint-only(sm) {
        grid-template-rows: repeat(3, minmax(50px, min-content));
        grid-template-columns: minmax(0, 42px) 1fr minmax(0, 42px);
        grid-template-areas: 'left left left' 'quote quote quote' ' right right right';
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
  @include e(quote-text) {
    @supports (display: grid) {
      grid-area: quote;
    }
  }
  @include e(quote-left) {
    color: $color-primary;
    font-size: 80px;
    line-height: 1px;
    display: inline-flex;
    align-items: flex-start;
    margin-bottom: auto;
    transform: translate(-10px, 34px);

    @supports (display: grid) {
      grid-area: left;
      transform: translate(0, 0);
      line-height: 1em;
      display: inline-flex;
      max-height: 50px;
      @include media-breakpoint-only(sm) {
        height: 42px;
        overflow: hidden;
        transform: translate(0, 0);
      }
    }
  }
  @include e(quote-right) {
    color: $color-primary;
    font-size: 80px;
    line-height: 1px;
    display: inline-flex;
    align-items: flex-end;
    margin-top: auto;
    transform: translate(10px, 0px);

    @supports (display: grid) {
      grid-area: right;
      transform: translate(0, 0);
      line-height: 1em;
      display: inline-flex;
      max-height: 50px;
      align-items: flex-end;
      justify-content: flex-end;
      @include media-breakpoint-only(sm) {
        height: 32px;
        overflow: hidden;
        align-items: flex-end;
        transform: translate(0, -20px);
      }
    }
  }

  .eyebrow {
    margin-top: 30px;
  }

  @include m(scotch) {
    @include e(quote-left) {
      color: map-get($rogers-colors, body);
    }
    @include e(quote-right) {
      color: map-get($rogers-colors, body);
    }
  }
}
