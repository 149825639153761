// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

$caret-blue: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDUycHgiIGhlaWdodD0iMjU4cHgiIHZpZXdCb3g9IjAgMCA0NTIgMjU4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImFycm93LWRvd24tc2lnbi10by1uYXZpZ2F0ZSIgZmlsbD0iIzAwODJCQiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTIyNS45MjMsMjU3LjcwNiBDMjE3LjgyNSwyNTcuNzA2IDIwOS43MjgsMjU0LjYxNCAyMDMuNTU0LDI0OC40NDMgTDkuMjcsNTQuMTU3IEMtMy4wODksNDEuNzk4IC0zLjA4OSwyMS43NiA5LjI3LDkuNDA2IEMyMS42MjQsLTIuOTQ4IDQxLjY1OCwtMi45NDggNTQuMDE4LDkuNDA2IEwyMjUuOTIzLDE4MS4zMjEgTDM5Ny44MjksOS40MTIgQzQxMC4xODgsLTIuOTQyIDQzMC4yMiwtMi45NDIgNDQyLjU3Myw5LjQxMiBDNDU0LjkzOCwyMS43NjYgNDU0LjkzOCw0MS44MDQgNDQyLjU3Myw1NC4xNjMgTDI0OC4yOTIsMjQ4LjQ0OSBDMjQyLjExNSwyNTQuNjIxIDIzNC4wMTgsMjU3LjcwNiAyMjUuOTIzLDI1Ny43MDYgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==';

// Module styles
@include b(item-group) {
  $basic-icon-box-margin-lg-screens: 121px;
  $container-vertical-padding: 0;
  $min-height: 300px;
  $width: 50%;

  background-color: $color-white;
  min-height: $min-height;

  @include e('container') {
    @include make-container();
    @include make-container-max-widths();

    padding: 60px 0 0;
  }

  @include e('title') {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #979797;
    line-height: 0.1em;
    display: inherit;
    margin: auto auto 50px;
    color: #32434e;
    cursor: pointer;
    font-size: 18px;
    padding: 1px 10px 0;
    font-family: $ted-next;
    font-weight: $demibold;
    box-sizing: border-box;
    height: 3px;

    span {
      background: #fff;
      padding: 2px 10px;
    }

    @include media-breakpoint-down(sm) {
      width: 80%;
    }
  }

  @include e('items-row') {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    padding: 30px 10px;

    @include media-breakpoint-down(md) {
      // width: 80%;
      margin: 0 auto;
      padding: 15px 10px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      width: 100%;
      padding: 10px 10px;
    }
  }

  @include e('items-column') {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @include m('one') {
      width: 50%;
      align-items: center;
      margin-left: auto;
      margin-right: auto;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    @include m('two') {
      width: 50%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  @include e(inner-box-group) {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: $container-vertical-padding 0;
  }

  @include e('items-list-box') {
    padding: 0 50px;

    @include media-breakpoint-down(md) {
      padding: 0 30px;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 15px;
    }
  }

  .items-list-box {
    &__title {
      font-family: $ted-next;
      font-weight: 700;
      font-size: 32px;
      margin: 17px 0 28px;

      @include media-breakpoint-up(md) {
        font-size: 36px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 28px;
      }
    }

    &__description {
      color: $color-storm-grey;
      line-height: 1.56;
      max-width: 265px;
    }
  }

  @include m(scotch) {
    .items-list-box__link {
      .button--link {
        outline: none;
        color: map-get($rogers-colors, secondary-light);
        .button__text {
          &:after {
            background-image: url($caret-blue);
          }
        }
        &:hover {
          color: map-get($rogers-colors, secondary-light-hover);
        }
      }
    }
  }
}
