// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/grid';
@import 'style/utils/bem';

// Component styles
@include b(default-hero) {
  background: white;
  padding-bottom: 50px;
  min-height: 392px;
  background-color: white;

  @include media-breakpoint-down(md) {
    padding-bottom: 15px;
  }

  h1 {
    @include h1();
  }

  .c-disclaimer-cta {
    letter-spacing: 0px;
  }
  .c-hero__subtitle {
    .c-disclaimer-cta,
    sup {
      font-size: 0.8em;
    }
  }
}
