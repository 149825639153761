@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(scrollable-selector) {
  $animation-timing: 0.3s;
  $animation-function: ease-in-out;

  position: relative;

  @include media-breakpoint-only(md) {
    margin-left: 30px;
  }

  @include m(center-selector) {
    @include media-breakpoint-only(sm) {
      text-align: center;
    }
  }

  @include e(container) {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @include e(scrolling-container) {
    position: relative;

    .msie &,
    .msedge & {
      max-width: 100%;
    }

    @include media-breakpoint-only(sm) {
      display: inline-block;
      white-space: nowrap;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 36px;
      align-self: flex-start;
    }
  }

  @include e(select-indicator) {
    position: absolute;
    left: -2px;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: #edf2f4;
    display: none;
    overflow: visible;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  @include e(select-indicator-element) {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 4px;
    background-color: $color-azure;
    border-radius: 2px;
    transition:
      height $animation-timing $animation-function,
      transform $animation-timing $animation-function;
  }

  @include e(item) {
    list-style: none;
    margin: 0 15px 0 30px;
    position: relative;
    background-color: transparent;
    border: none;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: $color-primary;
      opacity: 0;
      transition: opacity $animation-timing $animation-function;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    @include m(selected) {
      .scrollable-selector__button {
        color: map-get($rogers-colors, primary-light);
      }

      .scrollable-selector__sub-copy {
        color: $color-bluish-grey;
        opacity: 1;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  @include e(button) {
    @include h3();
    font-size: 20px;
    line-height: 22px;
    text-align: left;
    color: $color-oxford-blue;
    transition: color $animation-timing $animation-function;
  }

  @include e(sub-copy) {
    display: none;
    transition: opacity $animation-timing $animation-function;

    @include media-breakpoint-up(md) {
      display: block;
      opacity: 0.2;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.1px;
      color: $color-oxford-blue;
    }
  }

  @include e(item) {
    cursor: pointer;

    @include media-breakpoint-up(md) {
      margin-bottom: 50px;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .no-touch &:hover {
      .scrollable-selector__button {
        color: map-get($rogers-colors, primary-light);
      }

      .scrollable-selector__sub-copy {
        color: $color-bluish-grey;
        opacity: 1;
      }
    }
  }

  @include m(hide-subcopy) {
    .scrollable-selector__sub-copy {
      display: none;
    }

    @include e(item) {
      @include media-breakpoint-up(md) {
        margin-bottom: 36px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
