// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

$caret-blue: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDUycHgiIGhlaWdodD0iMjU4cHgiIHZpZXdCb3g9IjAgMCA0NTIgMjU4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImFycm93LWRvd24tc2lnbi10by1uYXZpZ2F0ZSIgZmlsbD0iIzAwODJCQiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTIyNS45MjMsMjU3LjcwNiBDMjE3LjgyNSwyNTcuNzA2IDIwOS43MjgsMjU0LjYxNCAyMDMuNTU0LDI0OC40NDMgTDkuMjcsNTQuMTU3IEMtMy4wODksNDEuNzk4IC0zLjA4OSwyMS43NiA5LjI3LDkuNDA2IEMyMS42MjQsLTIuOTQ4IDQxLjY1OCwtMi45NDggNTQuMDE4LDkuNDA2IEwyMjUuOTIzLDE4MS4zMjEgTDM5Ny44MjksOS40MTIgQzQxMC4xODgsLTIuOTQyIDQzMC4yMiwtMi45NDIgNDQyLjU3Myw5LjQxMiBDNDU0LjkzOCwyMS43NjYgNDU0LjkzOCw0MS44MDQgNDQyLjU3Myw1NC4xNjMgTDI0OC4yOTIsMjQ4LjQ0OSBDMjQyLjExNSwyNTQuNjIxIDIzNC4wMTgsMjU3LjcwNiAyMjUuOTIzLDI1Ny43MDYgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==';
// Component styles
.items-list-box {
  text-align: left;
  padding-top: 38px;
  padding-bottom: 30px;
  width: 100%;

  &__icon {
    position: relative;
    text-align: center;
    color: $color-primary;
    margin: 0;
    padding: 0;

    svg {
      max-height: 34px;
      line-height: 1;
    }
  }

  &__title {
    color: $color-oxford-blue;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;

    @include media-breakpoint-only(md) {
      margin-top: 26px;
      font-weight: 500px;
      margin-bottom: 16px;
    }

    @include media-breakpoint-only(lg) {
      margin-top: 32px;
      margin-bottom: 23px;
    }

    @include media-breakpoint-up(md) {
      font-size: 20px;
      font-family: $ted-next;
      letter-spacing: 0.2px;
    }
  }

  &__link {
    display: block;
    padding: 0;
    margin-bottom: 15px;
    text-align: center;
    .button {
      span {
        &:after {
          content: '';
          display: inline-block;
          transform: translateX(5px) rotate(-90deg);
          height: 10px;
          width: 10px;
          transition: transform 0.1s ease-in-out;
          background-size: 8px;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url($caret-blue);
        }
      }
      &:hover {
        color: map-get($colors, secondary);
        span {
          &:after {
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDUycHgiIGhlaWdodD0iMjU4cHgiIHZpZXdCb3g9IjAgMCA0NTIgMjU4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkNhcmV0IiBmaWxsPSIjOTQ2NEZEIiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgICAgICAgICA8cGF0aCBkPSJNMjI1LjkyMywyNTcuNzA2IEMyMTcuODI1LDI1Ny43MDYgMjA5LjcyOCwyNTQuNjE0IDIwMy41NTQsMjQ4LjQ0MyBMOS4yNyw1NC4xNTcgQy0zLjA4OSw0MS43OTggLTMuMDg5LDIxLjc2IDkuMjcsOS40MDYgQzIxLjYyNCwtMi45NDggNDEuNjU4LC0yLjk0OCA1NC4wMTgsOS40MDYgTDIyNS45MjMsMTgxLjMyMSBMMzk3LjgyOSw5LjQxMiBDNDEwLjE4OCwtMi45NDIgNDMwLjIyLC0yLjk0MiA0NDIuNTczLDkuNDEyIEM0NTQuOTM4LDIxLjc2NiA0NTQuOTM4LDQxLjgwNCA0NDIuNTczLDU0LjE2MyBMMjQ4LjI5MiwyNDguNDQ5IEMyNDIuMTE1LDI1NC42MjEgMjM0LjAxOCwyNTcuNzA2IDIyNS45MjMsMjU3LjcwNiBaIiBpZD0iUGF0aCI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');

            transform: translateX(7px) rotate(-90deg);
          }
        }
      }
    }
  }

  @include media-breakpoint-only(md) {
    padding-top: 48px;
    padding-bottom: 25px;
  }

  @include media-breakpoint-only(lg) {
    padding-top: 8px;
    padding-bottom: 25px;
  }

  @include e('cta-container') {
    text-align: center;
    padding-top: 15px;
  }

  @include m(scotch) {
    .items-list-box__link {
      .button--link {
        outline: none;
        color: map-get($rogers-colors, secondary-light);
        .button__text {
          &:after {
            background-image: url($caret-blue);
          }
        }
        &:hover {
          color: map-get($rogers-colors, secondary-light-hover);
        }
      }
    }
  }
}
