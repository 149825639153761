// Application mixins
@import 'style/utils/bem';
@import 'style/grid/breakpoints';

// Module styles
@include b(collapsable) {
  @include media-breakpoint-down(md) {
    display: block;
  }
}
