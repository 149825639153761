// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';

// Module styles
@include b(checkbox) {
  @include e(info) {
    font-size: 14px;
    color: map-get($colors-neutral, medium);
    display: inline-block;
    margin-top: 10px;
    line-height: 1.4em;
  }

  @include e(label) {
    color: map-get($colors-neutral, dark);
    padding: 10px;
    margin: 10px 0;
    display: flex;
    cursor: pointer;

    input[type='checkbox'] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: white;
      min-width: 20.8px;
      /* Not removed via appearance */

      font: inherit;
      color: currentColor;
      width: 1.3em;
      height: 1.3em;
      border: 1px solid map-get($rogers-colors, link-dark);
      border-radius: 0.15em;
      transform: translateY(-0.075em);

      display: grid;
      place-content: center;
    }
    input[type='checkbox']:checked {
      border-color: map-get($rogers-colors, primary-light);
      background-color: map-get($rogers-colors, primary-light);
    }
    input[type='checkbox']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em white;
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }

    input[type='checkbox']:checked::before {
      transform: scale(1);
    }

    input[type='checkbox']:focus {
      outline: max(0.15em, 0.15em) solid $color-outline;
      outline-offset: max(0.15em, 0.15em);
    }

    span {
      align-self: center;
      font-size: 14px;
      margin-left: 10px;
    }
  }
}
