// Application mixins
@import 'style/globals/colors';
@import 'style/utils/bem';

@include b(chevron) {
  height: 15px;
  width: 28px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 1px;
    height: 2px;
    left: 4px;
    width: 10px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: map-get($colors, primary);
    // transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg);
    transform-origin: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    height: 2px;
    right: 5px;
    width: 10px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: map-get($colors, primary);
    // transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg);
    transform-origin: 0;
  }

  @include m(small) {
    height: 10px;
    width: 17px;

    &::before {
      width: 8px;
      left: 1px;
      transform: translate3d(0, 7px, 0) rotate(45deg);
    }

    &::after {
      width: 8px;
      right: 1px;
      transform: translate3d(0, 7px, 0) rotate(-45deg);
    }
  }

  @include m(thin) {
    transform: scale(0.55);
  }

  @include m(whiteChevron) {
    &::before,
    &::after {
      background-color: #fff;
    }
  }

  @include m(blueChevron) {
    &::before,
    &::after {
      background-color: map-get($colors, primary);
    }

    &:hover {
      &:before,
      &:after {
        background-color: white !important;
      }
    }
  }

  @include m(fast) {
    &::before,
    &::after {
      // transition: all 50ms cubic-bezier(0.86, 0, 0.07, 1);
    }
  }

  @include m(open) {
    &::before {
      transform: translate3d(0, 0, 0) rotate(-45deg);
    }

    &::after {
      transform: translate3d(0, 0, 0) rotate(45deg);
    }
  }
}

//top: 7px;
//height: 2px;
//left: 0;
//width: 9px
//
// top: 7px;
//height: 2px;
//right: 1px;
//width: 9px;;
