// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

.c-feature-list {
  .c-feature-list__feature-boxes--columns-2 {
    @include media-breakpoint-down(md) {
      .c-feature-card {
        max-width: none;
      }
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  }
  .c-feature-list__title--with-eyebrow {
    margin-top: 20px;
  }
  .c-basic-text-box__description {
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.6em;
  }
  .c-text-card {
    .c-text-card__title {
      margin-bottom: 15px;
    }
    .c-text-card__description {
      margin-bottom: 30px;
    }

    .c-link {
      outline: none !important;
      &:active,
      &:focus {
        outline: none !important;
      }
    }
  }
  .c-feature-list__feature-boxes {
    .c-basic-icon-box {
      margin-left: auto;
      margin-right: auto;
    }

    &.c-feature-list__feature-boxes--columns-2 {
      .c-basic-icon-box {
        max-width: 360px;
      }
    }

    .c-feature-card {
      .c-feature-card__button {
        margin-top: auto;
        .c-button__link {
          &.c-button__link--Link_ChevronBlue {
            &:after {
              font-family: 'shaw-icons' !important;
              content: '\e92f';
              font-size: 14px;
              display: inline-block;
              transform: translate(10px, 1px);
              text-decoration: none !important;
            }
          }
        }
        .button {
          font-weight: $demibold;
          font-size: 16px;
        }
      }
    }
  }
  .c-basic-icon-box {
    .c-basic-icon-box__icon {
      margin-bottom: 10px !important;
    }
    .c-basic-icon-box__title {
      margin: 15px 0;
    }
  }
}
