// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/grid';
@import 'style/utils/bem';

@include b('eyebrow') {
  font-family: $ted-next;
  font-weight: $demibold;
  display: block;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 10px;

  .c-disclaimer-cta {
    letter-spacing: 0px;
  }

  @include media-breakpoint-up(md) {
    width: 100%;
  }
  @include m(center) {
    text-align: center;
  }
  @include m(left) {
    text-align: left;

    @include media-breakpoint-only(sm) {
      text-align: center;
    }
  }
  @include m(right) {
    text-align: right;
    @include media-breakpoint-only(sm) {
      text-align: center;
    }
  }
}
