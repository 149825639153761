// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'app/components/IconBadge/IconBadge';

// Component styles
@include b('product-offer-card') {
  color: $color-oxford-blue;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-only(sm) {
    margin-bottom: 75px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .card__container {
    height: 100%;
    padding: 0;
    width: 100%;

    @include media-breakpoint-only(sm) {
      padding: 0 40px;
      box-shadow: none;
    }

    .safari & {
      height: auto;
    }
  }

  .card__content {
    height: 100%;
    width: 100%;
  }

  @include m('vertical') {
    @include e('features') {
      @include media-breakpoint-up(md) {
        margin-bottom: 25px;
        width: 300px;

        @include media-breakpoint-up(lg) {
          width: 350px;
        }

        &-list {
          height: 190px;

          .product-feature-item {
            margin-bottom: 15px;
          }

          @include media-breakpoint-up(md) {
            height: 180px;
          }

          @include media-breakpoint-up(lg) {
            height: 190px;
          }
        }
      }
    }

    @include e('short-description') {
      @include media-breakpoint-up(lg) {
        max-width: 300px;
        height: 72px;
        margin-bottom: 33px;
      }
    }
  }

  &:not(&--vertical) {
    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex: 1 1;
      padding: 0;
      height: 390px;
    }

    @include media-breakpoint-up(lg) {
      height: 410px;
    }

    .product-offer-card__block {
      @include media-breakpoint-up(md) {
        justify-content: center;
        flex: 1;
        padding: 0 30px;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 60px;
      }

      &:nth-child(2) {
        &::after {
          @include media-breakpoint-up(md) {
            height: 272px;
            right: 0;
            left: 50%;
            content: '';
            width: 1px;
            position: absolute;
            background-color: $color-geyser;
            top: 74px;
          }

          @include media-breakpoint-up(lg) {
            top: 78px;
          }
        }
      }
    }

    .product-offer-card__title {
      @include media-breakpoint-up(md) {
        height: 34px;
        margin-bottom: 10px;
        margin-top: 74px;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 14px;
        margin-top: 78px;
        max-width: unset;
      }
    }

    .product-offer-card__short-description {
      @include media-breakpoint-up(md) {
        padding: 0 30px;
        max-width: 300px;
        margin-bottom: 18px;
        min-height: unset;
      }

      @include media-breakpoint-up(lg) {
        max-width: unset;
        margin-bottom: 25px;
      }
    }

    .product-offer-card__features {
      border: 0;
      flex-grow: 1;

      @include media-breakpoint-up(md) {
        margin-bottom: 50px;

        &::before,
        &::after {
          display: none;
        }
      }

      &-list {
        .product-feature-item {
          margin-bottom: 15px;
        }
        @include media-breakpoint-up(md) {
          padding: 0;
          justify-content: space-between;
        }
      }
    }

    .product-offer-card__price-block {
      @include media-breakpoint-up(md) {
        padding-top: 10px;
        margin-bottom: 20px;
      }
    }

    .product-offer-card__button {
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }

  @include e('block') {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: auto;
  }

  @include e('title') {
    margin-bottom: 16px;
    margin-top: 70px;
    text-align: center;
    @include h2();

    @include media-breakpoint-only(md) {
      font-size: 28px;
      margin-top: 74px;
      letter-spacing: -1px;
    }

    @include media-breakpoint-only(sm) {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 0.2px;
      margin-top: 65px;
    }
  }

  @include e('short-description') {
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: $color-storm-grey;
    margin-bottom: 44px;

    @include media-breakpoint-only(md) {
      max-width: 350px;
    }

    @include media-breakpoint-only(md) {
      font-size: 16px;
      max-width: 300px;
      margin-bottom: 33px;
      min-height: 72px;
    }

    @include media-breakpoint-only(sm) {
      font-size: 16px;
      line-height: 1.5;
      max-width: 90%;
      margin-bottom: 33px;
    }
  }

  @include e('features') {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 55px;

    @include media-breakpoint-only(sm) {
      height: 210px;
      margin-bottom: 35px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      border-top: 1px solid $color-geyser;
      width: 100%;

      @include media-breakpoint-only(md) {
        width: 282px;
      }
    }

    @include m('no-bottom-line') {
      &::after {
        content: none;
      }
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    &-list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 22px 0;

      @include media-breakpoint-only(md) {
        width: auto;
        max-width: none;
        padding: 15px 0;
      }
    }
  }

  @include e('price-block') {
    text-align: center;
    margin-bottom: 40px;
    letter-spacing: 0.05px;

    @include media-breakpoint-only(md) {
      margin-bottom: 34px;
    }

    @include media-breakpoint-only(sm) {
      margin-bottom: 36px;
    }
  }

  @include e('text-above') {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: center;
    color: $color-storm-grey;
    margin-bottom: 10px;
  }

  @include e('price') {
    font-family: $ted-next;
    color: black;
    font-size: 28px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
    margin-bottom: 10px;
  }

  @include e('price-symbol') {
    font-size: 18px;
    position: relative;
    line-height: 16px;
    top: -5px;
    left: 2px;
    margin-right: 5px;
  }

  @include e('asterisk-symbol') {
    font-size: 12px;
    position: relative;
    top: -12px;
    right: 7px;
  }

  @include e('per-text') {
    font-size: 20px;
    align-self: flex-end;
    line-height: 24px;
    position: relative;
    top: -2px;

    @include media-breakpoint-only(md) {
      font-size: 20px;
      top: 0;
    }

    @include media-breakpoint-only(sm) {
      font-size: 18px;
    }
  }

  @include e('text-below') {
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: $color-storm-grey;
    max-width: 400px;

    @include media-breakpoint-up(md) {
      height: 24px;
    }
  }

  @include e('button') {
    margin-bottom: 70px;

    @include media-breakpoint-only(md) {
      margin-bottom: 50px;
    }

    @include media-breakpoint-only(sm) {
      margin-bottom: 40px;
    }
  }

  @include e('icon-badge') {
    left: calc(50% - #{$icon-size / 2});

    @include media-breakpoint-up(lg) {
      left: calc(50% - #{$icon-size / 2});
    }
  }

  @include m(scotch) {
    .product-offer-card__title {
      font-family: $ted-next;
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;
      text-transform: none;
    }
    .product-offer-card__price-symbol {
      font-size: 32px;
      top: 0;
      left: 0;
      margin-right: 0;
    }
    .product-offer-card__text-above {
      margin-bottom: 0;
    }
    .product-offer-card__price {
      font-family: $ted-next;
      font-size: 32px;
      letter-spacing: 0px;
      margin: 0;
    }
    .product-offer-card__per-text {
      font-size: 18px;
    }
    .product-feature-item .icon:before {
      color: map-get($rogers-colors, primary-light);
    }
    .icon-badge .icon:before {
      color: map-get($rogers-colors, primary-light);
    }
  }
}
