// Application mixins
@import 'style/globals/animations';
@import 'style/utils/bem';
@import 'style/grid/grid';

// Module styles
@include b(form-field-group) {
  .form-set__item {
    &:first-child {
      margin-right: 20px;

      @include media-breakpoint-up(sm) {
        margin-right: 0;
      }
    }
  }
}
