// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

.chat-bar {
  position: relative;

  $padding-top-sm: 24px;
  $padding-bottom-sm: 22px;
  $padding-left-right-sm: 24px;
  $padding-left-right-md: 40px;

  min-height: 67px;
  width: 32px;
  transition: width 0.5s ease-in-out;

  @include media-breakpoint-only(lg) {
    min-height: 0;
  }

  @include media-breakpoint-down(md) {
    margin-right: 15px;
  }

  @mixin close-button-line($deg) {
    content: '';
    width: 14px;
    height: 1px;
    background-color: $color-oxford-blue;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate($deg);

    @include media-breakpoint-only(lg) {
      background-color: $color-oxford-blue;
    }
  }

  .global-nav__chat & {
    display: flex;
    align-items: center;
  }

  @include m(opened) {
    width: auto;

    .chat-bar {
      &__input-container {
        pointer-events: all;
        opacity: 1;
        width: auto;

        [aria-label='Chat offline'] {
          opacity: 0.5;
        }

        @include media-breakpoint-down(md) {
          position: fixed;
          top: 60px;
          height: 50px;
          left: 0;
          width: 100%;
          background: $color-white;
          display: flex;
          padding-left: 30px;
        }

        @include media-breakpoint-only(sm) {
          top: 50px;
          height: auto;
        }
      }

      &__button {
        display: none;
      }

      &__close-button {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 999;
        // transform: translate3d(0, -50%, 0);
      }

      &__close-button:focus {
        outline: solid 3px $color-outline;
        outline-offset: -3px;
      }

      &__close-button:focus::before {
        background-color: $color-azure;
      }

      &__close-button:focus::after {
        background-color: $color-azure;
      }

      &__label {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }

  @include e(icon) {
    font-size: 15px;
    margin-right: 0;
    transform: translate3d(0, 2px, 0);
  }

  @include e(label) {
    display: none;
  }

  @include e(button) {
    background-color: $color-transparent;
    border: 0;
    cursor: pointer;
    text-align: left;
    color: $color-oxford-blue;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .no-touch &:hover {
      color: $color-azure;
    }

    &:focus {
      outline: solid 3px $color-outline;
      outline-offset: -3px;
    }

    &:focus > span.icon::before {
      color: $color-azure;
    }
  }

  @include e(input-container) {
    pointer-events: none;
    opacity: 0;
    position: relative;
    height: 100%;
    width: auto;

    .msie &,
    .msedge & {
      width: 98.5%;
    }

    @include media-breakpoint-only(lg) {
      z-index: 1;
      display: flex;
      background: $color-white;
      width: 0;
    }

    @include media-breakpoint-only(md) {
      z-index: 1;
      position: fixed;
      top: 90px;
      width: 100%;
      height: 30px;
      left: 0;
      background: #fff;
      display: flex;
      align-items: center;
    }
  }

  @include e(close-button) {
    position: relative;
    top: 15px;
    height: 32px;
    width: 32px;
    border: 0;
    transform: translate3d(0, -50%, 0);
    background: $color-transparent;
    transition: transform 0.5s ease-in-out;
    cursor: pointer;
    display: none;

    .no-touch &:hover {
      &::after,
      &::before {
        background-color: $color-azure;
      }
    }

    .no-touch .global-nav--scotch &:hover,
    .global-nav--scotch &:focus {
      outline: none;
      &::after,
      &::before {
        background-color: map-get(
          $rogers-colors,
          primary-light-hover
        ) !important;
      }
    }

    &:focus {
      outline: none;
    }

    &::after {
      @include close-button-line(45deg);
    }

    &::before {
      @include close-button-line(-45deg);
    }
  }

  @include e(links) {
    display: flex;
    list-style-type: none;

    @include media-breakpoint-down(md) {
      width: 100%;
      justify-content: space-evenly;
    }

    @include media-breakpoint-down(sm) {
      width: 80%;
      flex-flow: column;
      justify-content: center;
      margin: 0 auto;
    }

    @include m('hidden') {
      display: none;
    }
  }

  @include e(link) {
    font-size: 16px;
    list-style: none;
    .global-nav--scotch &:focus {
      outline: none;
    }
    a {
      padding: 15px;
      display: flex;
      outline: none;
      align-items: center;
      &.hide {
        display: none;
      }
      &.chat-unavailable {
        &:hover {
          color: $color-light-grey;
        }
      }

      .link__linkblock {
        display: flex;
        align-items: center;
        .icon {
          transform: translateY(0px);
        }
      }

      .no-touch &:hover,
      &:focus {
        color: $color-primary-cta;
      }
      .no-touch .global-nav--scotch &:hover,
      .global-nav--scotch &:focus {
        outline: none;
        color: map-get($rogers-colors, primary-light-hover);

        .link__text {
          text-decoration: underline;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      a {
        display: flex;
        justify-content: center;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  @include e('tooltip') {
    position: absolute;
    border-radius: 0 0 4px 4px;
    border: 0;
    border-bottom: 4px solid map-get($rogers-colors, primary-light);
    background: $color-white;
    padding: 40px 50px 20px 50px;
    max-width: 300px;
    z-index: -1;
    font-family: $ted-next;
    font-size: 15px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.14);

    @include m('open') {
      opacity: 1;
      z-index: 10000;
    }
    @include media-breakpoint-only(md) {
      left: 0;
      top: 110px;
      right: 0;
    }
    @include media-breakpoint-only(sm) {
      left: 0;
      top: 56px;
      right: 0;
      width: 100%;
      max-width: 100vw;
      border-radius: 0;
      font-size: 16px;
      padding: 40px 20px;
      box-sizing: content-box;

      br {
        display: none;
      }
    }
  }

  @include e('tooltip-header') {
    display: none;
    position: absolute;
    left: 30px;
    top: 0;
    right: 30px;
    height: 60px;
    padding: 20px 10px 0;
    font-family: $ted-next;
    font-size: 22px;

    @include media-breakpoint-only(sm) {
      display: none;
    }
  }

  @include e('tooltip-close') {
    position: absolute;
    top: 7px;
    right: 7px;
    color: $color-black;

    &:hover {
      color: map-get($rogers-colors, primary-light-hover);
    }

    span::before {
      font-size: 12px;
    }

    @include media-breakpoint-only(sm) {
      color: $color-oxford-blue;
      top: 20px;
      right: 30px;

      span::before {
        font-size: 20px;
      }
    }
  }

  @include e('tooltip-body') {
    text-align: center;

    @include media-breakpoint-only(sm) {
      text-align: left;
      padding: 10px;
    }
  }
  .chat-tooltip {
    border-radius: 4px;
    background-color: var(--rt-color-white);
    color: var(--rt-color-dark);
    border: 1px solid map-get($colors-scotch, medium-grey);
    border-bottom: 5px solid map-get($rogers-colors, primary-light);
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.2);
  }
  .chat-tooltip-arrow {
    border-right: 1px solid map-get($colors-scotch, medium-grey);
    border-bottom: 1px solid map-get($colors-scotch, medium-grey);
  }
  .chat-tooltip-title {
    margin-bottom: 5px;
    padding: 5px;
  }
  .chat-tooltip-content {
    padding: 5px;
    line-height: 1.4em;
  }
}
