// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/spacing';
@import 'style/grid/grid';

// Component styles
.product-add-on-icon-box {
  padding: 4px from-columns(0.5);
  text-align: center;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(2, minmax(min-content, auto)) 1fr repeat(
      2,
      minmax(min-content, auto)
    );

  &__icon {
    position: relative;
    grid-row: 1 / span 1;
  }

  &__name {
    color: $color-oxford-blue;
    margin: 25px auto 10px;
    font-size: 22px;
    font-weight: 700;
    font-family: $ted-next;
    letter-spacing: -0.9px;
    grid-row: 2 / span 1;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  &__description {
    color: $color-bluish-grey;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
    grid-row: 3 / span 1;

    @include media-breakpoint-up(md) {
      line-height: 1.44;
    }
  }

  &__price-block {
    color: $color-oxford-blue;
    font-size: 28px;
    margin-top: auto;
    font-family: $ted-next;
    letter-spacing: 0.2px;
    grid-row: 4 / span 1;

    sup {
      font-size: 14px;
    }

    @include media-breakpoint-up(md) {
      margin-top: 24px;
    }
  }

  &__price {
    font-weight: 700;
  }

  &__price-prefix,
  &__price-suffix {
    color: $color-bluish-grey;
    margin: 2px auto;
    display: block;
    font-size: 16px;
    line-height: 1.5;
  }

  &__price-suffix {
    margin-top: -3px;
  }

  &__price-terms {
    font-size: 20px;

    sup {
      top: -12px;
      left: -6px;
      font-size: 10px;
    }
  }

  &__cta {
    margin-top: 24px;
    grid-row: 5 / span 1;

    .button .icon {
      font-size: 8px;
      padding-top: 0;
    }

    .icon-Link_ChevronWhite {
      transform: translate3d(0, 3px, 0);
    }
  }

  @include media-breakpoint-up(md) {
    padding: 4px 18px;
  }
}
