// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';

@include b(region-selector) {
  background: white;

  @include e(container) {
    width: 100%;
    max-width: $lg-min-width;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    form {
      max-width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding: 0;
    }
  }

  @include e(selector) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @media (min-width: 500px) {
      flex-direction: row;
      align-items: center;
      width: auto;
    }
  }

  @include e(info) {
    width: 100%;
    text-align: center;
    color: $color-dust-grey;
    margin-top: 15px;
    margin-bottom: 15px;

    @media (max-width: 736px) {
      text-align: left;
      align-self: flex-start;
    }
  }

  @include e(label) {
    font-family: $ted-next;
    font-weight: 700;
    font-size: 14px;
    flex: 2;

    .region-selector--scotch & {
      font-family: $ted-next;
    }

    @include media-breakpoint-up(md) {
      flex: 1;
      width: auto;
    }
  }

  @include e(options) {
    padding-bottom: 2px;
    .select-css {
      min-width: 170px;
      flex: 1;
      display: inline-block;
      font-size: 14px;
      font-family: $ted-next;
      color: $color-azure;
      line-height: 1.4;
      padding: 0.3em 1em 0.1em 0.6em;
      max-width: 100%;
      box-sizing: border-box;
      border: 1px solid $color-dust-grey;
      box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
      background-repeat: no-repeat, repeat;
      background-position:
        right 0.7em top 50%,
        0 0;
      background-size:
        0.65em auto,
        100%;
      margin: 0 10px 0 0;
      width: 60%;

      @include media-breakpoint-up(md) {
        margin-left: 10px;
        width: 180px;
      }

      .region-selector--scotch &,
      .global-nav-menu__items--scotch & {
        outline: none;
        font-family: $ted-next;
        padding: 0.3em 1em 0.3em 0.6em;
        color: map-get($rogers-colors, body);
      }
    }

    .select-css::-ms-expand {
      display: none;
    }

    .select-css:hover {
      border-color: #888;
    }

    .select-css:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
      box-shadow: 0 0 0 3px -moz-mac-focusring;
      color: #222;
      outline: none;
    }

    .select-css option {
      font-weight: normal;

      &:hover {
        background-color: $color-primary;
      }
    }

    .button.button--primary {
      padding: 0 20px;
      height: 28px;
      font-size: 14px;
      width: fit-content;

      @include media-breakpoint-up(md) {
        width: fit-content;
      }
    }
    .button--scotch-primary {
      outline: none;
      padding: 0 20px;
      height: 30px;
      font-size: 14px;
      width: fit-content;
    }
  }

  @include e(location-selector) {
    margin: auto;
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    width: fit-content;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding: 0;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @include m(open) {
      overflow: visible;
      height: auto;
    }
  }
}

.msie .region-selector__selector {
  min-width: 416px;
}
