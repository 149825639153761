// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/grid';

@include b(video-box) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  padding: map-get($copy-spacing, md);

  @include e(eyebrow) {
    margin-bottom: 0;
  }
  @include e(title) {
    font-family: $ted-next;
    font-weight: $demibold;
    color: $color-oxford-blue;
    font-size: 24px;
  }
  @include e(description) {
  }
  @include e(buttons) {
    display: flex;
    column-gap: 10px;
  }
  @include e(video) {
    max-height: 250px;
  }
}
