// Application mixins
@import 'app/components/IconBadge/IconBadge';
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/grid/breakpoints';

// Component styles
@include b(card) {
  position: relative;
  @include m(scotch) {
    .card__icon {
      .icon-badge {
        span.icon {
          &:before {
            color: map-get($rogers-colors, primary-light);
          }
        }
      }
    }
  }
  @include m(shaw) {
    .card__icon {
      .icon-badge {
        span.icon {
          &:before {
            color: map-get($colors, primary);
          }
        }
      }
    }
  }
  &__icon {
    position: absolute;
    margin-left: -($icon-size/2);
    left: 50%;
    top: -($icon-size/2);
    height: $icon-size/2;

    @include media-breakpoint-only(md) {
      height: $icon-size/2;
      top: -($icon-size/2);
      margin-left: -($icon-size/2);
    }

    @include media-breakpoint-only(lg) {
      height: $icon-size/2;
      top: -($icon-size/2);
      margin-left: -($icon-size/2);
    }

    .icon-badge svg {
      max-height: 36px;
      max-width: 36px;
    }
  }

  &__container {
    box-shadow: 0 0 2px 1px rgba($color-black, 0.1);
    border-radius: 3px;
    background-color: $color-white;
  }

  &__icon + &__container {
    padding-top: $icon-size/2;

    @include media-breakpoint-only(md) {
      padding-top: $icon-size/2;
    }

    @include media-breakpoint-only(lg) {
      padding-top: $icon-size/2;
    }
  }

  &__content {
    display: flex;

    @include media-breakpoint-only(sm) {
      flex-direction: column;
    }
  }

  &--vertical &__content {
    flex-direction: column;
  }
}
