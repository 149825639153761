// Appliciation mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/utils/bem';

@mixin linkStyling() {
  a {
    position: relative;
    text-decoration: none;

    &::after {
      position: absolute;
      top: 5px;
      font-family: 'shaw-icons';
      font-size: 12px;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e92f';
      color: $color-primary-cta;
      transition: transform 300ms ease-in-out;
      transform: translate3d(5px, 0, 0);
    }

    &:hover {
      color: map-get($rogers-colors, link-light);
      text-decoration: underline;
      &::after {
        color: map-get($rogers-colors, link-light-hover);
        transform: translate3d(7px, 0, 0);
      }
    }
  }
}

// Component styles
@include b(hardware-feature-item) {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $color-mist;
  border-bottom: 1px solid $color-fog;

  &:last-child {
    border-bottom: none;
  }
  @include e(title) {
    @include h4();
  }

  @include e(main-container) {
    min-height: 314px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 40px;

    @include e(description) {
      margin-right: 70px;
    }

    @include media-breakpoint-only(md) {
      padding: 80px 0 70px;
      flex-direction: row;
    }

    @include media-breakpoint-only(lg) {
      padding: 60px 0 70px;
      flex-direction: row;
    }
  }

  @include e(secondary-container) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid $color-fog;
    padding: 36px 15px;

    .msie &,
    .msedge & {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
      }
    }

    @include media-breakpoint-only(sm) {
      max-width: 250px;
      margin: auto;
    }

    @include media-breakpoint-only(md) {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    @include media-breakpoint-only(lg) {
      flex-direction: row;
      align-items: flex-start;
      padding: 36px 50px;
    }
  }

  @include e(secondary-text-box) {
    .msie &,
    .msedge & {
      flex: 1 1 auto;
    }

    @include media-breakpoint-only(sm) {
      @include make-col(6);

      min-width: 100%;

      .safari & {
        flex: 0;
      }

      &:nth-child(n + 2) {
        margin-top: 40px;
      }
    }

    @include media-breakpoint-only(md) {
      @include make-col(6);

      &:nth-child(n + 3) {
        margin-top: 40px;
      }
    }

    @include media-breakpoint-only(lg) {
      @include make-col(4);

      min-width: 275px;
      max-width: 275px;

      &:nth-child(n + 2) {
        margin-top: 0;
      }
    }

    .basic-text-box {
      &__title {
        font-family: $ted-next;
        font-weight: $demibold;
        font-size: 16px;
        color: $color-oxford-blue;
        margin-bottom: 20px;
      }

      &__description {
        color: $color-bluish-grey;
        font-size: 16px;
        line-height: 1.5;
        max-width: 320px;

        @include linkStyling();
      }
    }
  }

  @include e(image-container) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
      flex: 1;
      padding-bottom: 0;

      .msie &,
      .msedge & {
        flex-basis: auto;
      }
    }
  }

  @include e(description-container) {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px;

    .msie &,
    .msedge & {
      flex-basis: auto;
    }

    @include media-breakpoint-only(sm) {
      max-width: 250px;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
  }

  @include e(image) {
    height: auto;
    max-width: 275px;
    max-height: 200px;
  }

  @include e(title) {
    font-family: $ted-next;
    font-size: 20px;
    color: $color-oxford-blue;
    letter-spacing: -0.8px;
    max-width: 250px;
    margin-bottom: 14px;

    @include media-breakpoint-only(md) {
      margin-left: 10px;
    }

    @include media-breakpoint-only(lg) {
      margin-bottom: 24px;
      margin-left: 55px;
    }
  }

  @include e(description) {
    flex: 1;
    color: #fafbfd;
    font-size: 16px;
    line-height: 1.5;
    max-width: 275px;

    .msie &,
    .msedge & {
      flex-basis: auto;
    }

    @include media-breakpoint-up(md) {
      max-width: 320px;
    }

    @include media-breakpoint-only(md) {
      margin-left: 10px;
    }

    @include media-breakpoint-only(lg) {
      margin-left: 55px;
    }

    @include linkStyling();
  }

  .basic-text-box {
    ul {
      margin-left: 17px;
      list-style-type: disc;

      li {
        padding-left: 15px;
      }
    }
  }
}
