$gutter: 30px;
$grid-lg: 1280px;
$grid-md: 84vw;
$grid-columns: 12;

@function from-columns($columns) {
  @return $columns / $grid-columns * 100%;
}

$component-padding: (
  lg: 80px,
  md: 64px,
  sm: 48px
);
