// Application mixins
@import 'style/utils/bem';
@import 'style/utils/accessibility';

// Module styles
@include b(link) {
  text-decoration: none;

  .button__scotch-primary &,
  .button__scotch-secondary & {
    color: initial;
  }

  a {
    text-decoration: none;
  }

  @include m(inherit) {
    color: inherit;
    a {
      color: inherit;
    }
  }

  @include e(text) {
    display: table-cell;
    vertical-align: middle;
    padding-left: 3px;

    @include m(hidden) {
      @include visuallyhidden();
    }
  }

  @include e(linkblock) {
    display: inline-table;
    vertical-align: middle;
    position: relative;
    z-index: 9;
  }
}
