@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/grid/breakpoints';
@import 'style/base/type';

@include b(price-information-new) {
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 0;
  max-width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 0 auto 0;
  grid-template-areas:
    'top top'
    'price price'
    'bottom bottom';

  @include e(price) {
    font-family: $ted-next;
    font-weight: 500;
    text-align: center;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    line-height: 1;
    margin: auto;
    width: 180px;
    grid-area: price;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: minmax(min-content, 24px) repeat(2, min-content);
    grid-template-areas:
      'topdisclaimer topdisclaimer'
      'price details'
      'disclaimer disclaimer';
  }
  @include e(price-details) {
    grid-area: details;
    justify-self: flex-start;
    align-items: center;
    justify-content: flex-start;
    justify-items: flex-start;
    display: grid;
    grid-row-gap: 0;
    line-height: 1;
    grid-template-columns: repeat(8, min-content);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      't1 t2 t3 t4 t5 tx tx tx tx'
      'b1 b2 b3 b4 b5 bt bt bt bt';
  }

  @include e(amount) {
    font-size: 24px;
    justify-self: flex-end;
    grid-area: price;
  }
  @include e(decimal) {
    grid-area: t1;
    font-size: 12px;
    align-self: flex-end;

    & + .price-information-new__divider {
      padding-left: 0;
      padding-right: 5px;
    }

    @include m(extra) {
      font-weight: bold !important;
    }
  }
  @include e(term) {
    padding-left: 0;
    padding-bottom: 4px;
    font-size: 8px;
    grid-area: bt;
    align-self: flex-start;
    display: flex;

    .rich-text {
      display: inline-block;
      margin-left: 3px;

      .c-disclaimer-cta {
        font-size: 1em;
        transform: translate(0, -0.3em);
        &:hover {
          text-decoration: none;
        }
      }
    }

    @include m(extra) {
      font-size: 12px !important;
    }

    @include media-breakpoint-only(sm) {
      padding: 0;
      transform: translateX(-4px);
    }
  }
  @include e(extra) {
    font-size: 12px;
    grid-area: tx;
    align-self: self-end;
    justify-self: flex-start;
    @include m(extra) {
      font-weight: bold;
      padding-left: 3px;
    }
  }
  @include e(divider) {
    grid-column: 2 / span 2;
    grid-row: 1 / -1;
    padding: 0;
    font-size: 24px;
    justify-self: center;
    align-self: end;

    @include m(extra) {
      padding: 0 !important;
      font-size: 26px !important;
    }
  }

  @include e(promo-text) {
    font-size: 10px;
    line-height: 16px;
    font-weight: normal;
    grid-area: disclaimer;
    min-width: 190px;
    max-width: 190px;
  }

  @include e(term-disclaimer) {
    grid-area: topdisclaimer;
    font-size: 10px;
    font-weight: 700;
    font-family: $ted-next;
    align-self: center;
  }

  @include m(is-promo) {
    justify-content: start;
    border-bottom: 1px solid map-get($colors-neutral, light);
    grid-template-columns: auto auto auto;
    grid-template-rows: auto 24px;
    grid-template-areas:
      'price price promo'
      'bottom bottom promo';

    @include e(price) {
      text-align: left;
      font-weight: bold;
      justify-items: flex-start;
      font-family: $ted-next;
      grid-column-gap: 5px;
      grid-template-columns: repeat(2, min-content) max-content;
      grid-template-areas:
        'topdisclaimer topdisclaimer gap'
        'price details gap'
        'disclaimer disclaimer disclaimer';
    }
    @include e(price-details) {
      grid-row-gap: 2px;
      grid-template-columns: repeat(8, minmax(min-content, 10px)) auto;
    }
    @include e(amount) {
      font-size: 48px;
      justify-self: flex-start;
    }
    @include e(decimal) {
      font-size: 18px;

      & + .price-information-new__divider {
        padding-left: 5px;
      }
    }
    @include e(extra) {
      font-size: 18px;
    }
    @include e(term) {
      font-size: 18px;
      padding-left: 0px;
      padding-bottom: 7px;
      .rich-text {
        .c-disclaimer-cta {
          font-size: 10px;
        }
      }
      @include m(extra) {
        font-size: 18px !important;
      }
    }
    @include e(term-disclaimer) {
      margin-top: 15px;
      padding-bottom: 5px;
      font-size: 18px;
      font-weight: 700;
      font-family: $ted-next;
      align-self: center;
    }
    @include e(divider) {
      font-size: 48px;
      padding: 0 5px 0 0;
      @include m(extra) {
        font-size: 44px !important;
      }
      @include media-breakpoint-only(sm) {
        padding: 0;
      }
    }

    @include e(offer) {
      grid-area: promo;
      background: transparent
        linear-gradient(
          180deg,
          map-get($colors-gradient, hover-start) 0%,
          map-get($colors-gradient, hover-end) 100%
        )
        0% 0% no-repeat padding-box;
      color: white;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 14px;
      font-weight: bold;
      text-align: center;
      margin-left: 15px;
      margin-right: 15px;
      @include media-breakpoint-only(sm) {
        margin-left: 7px;
        margin-right: 7px;
      }
    }
  }
  @include e(offer-text) {
    width: 54px;
    .c-disclaimer-cta {
      top: -0.5em;
    }
  }
}
