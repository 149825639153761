@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/utils/bem';

@include b(basic-text-box) {
  @include m(mobile-center) {
    @include media-breakpoint-only(sm) {
      text-align: center !important;
    }
  }
  @include m(mobile-left) {
    @include media-breakpoint-only(sm) {
      text-align: left !important;
      .eyebrow {
        text-align: left !important;
      }
    }
  }
  @include m(mobile-right) {
    @include media-breakpoint-only(sm) {
      text-align: right !important;
    }
  }
  @include e(title) {
    font-family: $ted-next;
    font-weight: $demibold;
    color: $color-oxford-blue;
    margin-bottom: 9px;
    font-size: 24px;
    line-height: 32px;
    @include media-breakpoint-only(sm) {
      margin-top: 10px;
    }
    @include m(normal) {
      font-weight: 500;
    }
    @include m(bold) {
      font-weight: 700;
    }
    @include m(black) {
      @include h3black();
    }
  }

  @include e(description) {
    font-family: $ted-next;
    font-weight: $medium;
    font-size: 16px;
    line-height: 1.56;
    letter-spacing: 0.1px;
    color: $color-storm-grey;

    ul,
    ol {
      margin-left: 20px;
      margin-right: 5px;
      li {
        margin-bottom: 7px;
      }
    }
  }
  @include e(buttons) {
    margin-top: 15px;
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    @include media-breakpoint-only(sm) {
      justify-content: center;
    }
  }
}
