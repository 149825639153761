// Application mixins
@import 'style/utils/bem';
@import 'style/globals/colors';
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/globals/animations';
@import 'style/grid/grid';

@include b(rich-text-box) {
  @include make-container();
  @include make-container-max-widths();

  padding: 2em;

  h1,
  h2,
  h3,
  h4 {
    font-family: $ted-next;
    margin-bottom: 1em;
  }
  h1 {
    @include h1();
    text-align: left;
  }

  p {
    margin-bottom: 1.5em;
    line-height: 1.4em;
    font-size: 15px;
    color: $color-promise-grey;
  }

  ul,
  ol {
    margin-left: 1.5em;
    font-size: 15px;
    line-height: 1.4em;
    margin-bottom: 1.5em;
    color: $color-promise-grey;
  }

  a {
    position: relative;
    text-decoration: none;
    color: map-get($rogers-colors, link-light);

    &:hover {
      text-decoration: underline;
      color: map-get($rogers-colors, link-light-hover);
    }
  }
}
