// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';

// Component Styles
.product-add-on-card {
  text-align: center;
  font-family: $ted-next;

  .card__content {
    padding-bottom: 45px;

    @include media-breakpoint-only(sm) {
      padding-bottom: 43px;
    }

    @include media-breakpoint-only(md) {
      padding-bottom: 35px;
    }
  }

  &__name {
    @include make-col(8);

    color: $color-oxford-blue;
    flex: 0;
    margin: 0 auto;
    margin-top: 32px;
    @include h3black();

    @include media-breakpoint-only(sm) {
      flex-basis: auto;
    }

    @include media-breakpoint-up(md) {
      margin-top: 34px;
    }
  }

  &__description {
    @include make-col(9);

    color: $color-bluish-grey;
    flex: 0;
    font-size: 16px;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    margin-top: 11px;

    @include media-breakpoint-only(sm) {
      flex-basis: auto;
    }

    @include media-breakpoint-up(md) {
      @include make-col(7);

      flex: 0;
      margin-top: 9px;
      line-height: 1.69;
    }

    @include media-breakpoint-up(lg) {
      @include make-col(8);

      flex: 0;
      margin-top: 9px;
      line-height: 1.69;
    }
  }

  &__price-block {
    color: $color-oxford-blue;
    font-size: 28px;
    margin-top: 24px;
    font-family: $ted-next;
    letter-spacing: 0.2px;

    @include media-breakpoint-only(sm) {
      flex-basis: 0;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }

    sup {
      font-size: 14px;
    }
  }

  &__price {
    position: relative;
    font-family: $ted-next;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
    color: #33444e;
    margin-bottom: 10px;
    word-wrap: break-word;
  }

  &__price-symbol {
    font-size: 18px;
    position: relative;
    line-height: 16px;
    top: -5px;
    left: 2px;
    margin-right: 5px;
  }

  &__asterisk-symbol {
    font-size: 12px;
    position: relative;
    top: -12px;
    right: 7px;
  }

  &__price-prefix,
  &__price-suffix {
    color: $color-bluish-grey;
    margin: 2px 6px;
    font-size: 16px;
    line-height: 1.5;
  }

  &__price-suffix {
    @include media-breakpoint-only(sm) {
      display: block;
    }
  }

  &__price-terms {
    font-size: 20px;

    sup {
      top: -12px;
      left: -6px;
      font-size: 12px;
    }
  }

  &__button {
    flex-basis: auto;
    margin-top: 30px;
    margin-bottom: 0;

    @include media-breakpoint-only(sm) {
      margin-top: 28px;
    }

    @include media-breakpoint-only(md) {
      margin-top: 27px;
    }
  }
}

.msie {
  .product-add-on-card {
    &__name {
      flex: 0 0 auto;

      @include media-breakpoint-only(sm) {
        flex-basis: auto;
      }
    }

    &__description {
      flex: 0 0 auto;

      @include media-breakpoint-only(sm) {
        flex-basis: auto;
      }

      @include media-breakpoint-up(md) {
        flex: 0 0 auto;
      }

      @include media-breakpoint-up(lg) {
        flex: 0 0 auto;
      }
    }

    &__price-block {
      @include media-breakpoint-only(sm) {
        flex-basis: auto;
      }
    }

    .card {
      &__content {
        align-items: center;
      }
    }
  }
}
