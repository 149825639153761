// Component styles
.full-bleed-background {
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: white;
  position: relative;
  // height: inherit;
  // margin: auto;

  // &__image {
  //   display: flex;
  //   flex: 1;
  //   justify-content: center;

  //   > img {
  //     height: 100%;
  //   }
  // }
}
