// Application mixins
@import 'style/base/type';
@import 'style/globals/colors';
@import 'style/grid/grid';
@import 'style/utils/bem';

.search-bar {
  position: relative;
  width: 32px;
  min-height: 48px;
  display: flex;

  $padding-top-sm: 0px;
  $padding-bottom-sm: 0px;
  $padding-left-right-sm: 16px;
  $padding-left-right-md: 24px;

  @include media-breakpoint-only(lg) {
    position: static;
    min-height: 0;
  }

  @include e(label) {
    color: $color-black;
    font-family: $ted-next;
    font-size: 14px;
    display: inline-flex;
    margin-top: 2px;
  }
  @include m('dropdown-menu') {
    width: 100%;

    @include e('input-container') {
      display: none;
    }
  }

  @mixin close-button-line($deg) {
    content: '';
    width: 14px;
    height: 1px;
    background-color: $color-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate($deg);

    @include media-breakpoint-only(lg) {
      background-color: $color-oxford-blue;
    }
  }

  .global-nav__search & {
    display: flex;
    align-items: center;
  }

  .search-results & {
    height: 80px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: $color-pale-grey;
    width: 100%;
  }

  @include m(opened) {
    width: auto;

    .search-bar {
      &__input-container {
        display: flex;
        width: 900px !important; /* stylelint-disable-line declaration-no-important */
        pointer-events: all;
        opacity: 1;

        @include media-breakpoint-down(md) {
          width: 100% !important; /* stylelint-disable-line declaration-no-important */
          margin: 0 auto;
          padding: 0;
        }
      }

      &__button {
        display: none;
      }

      &__input-wrapper {
        &::after {
          width: 100%;

          @include media-breakpoint-only(md) {
            width: 100%;
            display: flex;
            align-items: center;
            // margin-left: 0;
          }
        }

        > div {
          width: 100%;

          @include media-breakpoint-only(sm) {
            width: 90%;
          }

          .search-bar__result-item {
            display: block;
            padding: 7px 15px;
            font-family: $ted-next;
            cursor: pointer;
          }
        }

        .search-bar__submit-button {
          display: none;
        }
      }

      &__autocomplete {
        > .wrapper {
          border: 0;
          border-radius: 0;
          &:hover {
            box-shadow: none;
          }
          .search-icon {
            margin-left: 0;
          }

          .clear-icon {
            display: none !important;
          }
        }
      }

      &__close-button {
        transform: translate3d(0, -50%, 0);
      }

      &__close-button:focus {
        outline: solid 3px $color-outline;
        outline-offset: -3px;
      }

      &__close-button:focus::before {
        background-color: $color-azure;
      }

      &__close-button:focus::after {
        background-color: $color-azure;
      }

      &__input-wrapper > .search-bar__submit-button {
        // margin-right: 16px;
        color: $color-dust-grey;

        @include media-breakpoint-down(md) {
          display: inline-block;
        }
      }

      &__label {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }

  @include e(icon) {
    font-size: 15px;
    transform: translate3d(-3px, 1px, 0);

    .search-bar__button > & {
      @include media-breakpoint-up(lg) {
        margin-right: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      transform: translate3d(0, 0, 0);
      float: right;
    }
  }

  @include e(label) {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  @include e(button) {
    color: $color-white;
    background-color: $color-transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    padding: $padding-top-sm $padding-left-right-sm $padding-bottom-sm;
    text-align: left;
    width: 100%;
    align-items: center;

    .no-touch &:hover {
      color: $color-azure;
    }

    @include media-breakpoint-down(lg) {
      color: $color-search-grey;

      @include m(has-value) {
        color: $color-primary-cta;
        cursor: pointer;
        position: absolute;
        width: 10%;
        z-index: 999;

        .no-touch &:hover {
          color: $color-primary-hover;
        }
      }
    }

    .search-results & {
      display: none;
    }

    &:focus {
      outline: solid 3px $color-outline;
      outline-offset: -3px;
    }

    &:focus > span.icon::before {
      color: $color-azure;
    }

    @include media-breakpoint-only(md) {
      padding: $padding-top-sm $padding-left-right-md $padding-bottom-sm;
    }

    @include media-breakpoint-only(lg) {
      color: $color-oxford-blue;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }

  @include e('input-container') {
    pointer-events: none;
    opacity: 0;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;

    fieldset {
      width: 100%;
      border: none;
      position: relative;
      padding-bottom: 1px;
    }

    .msie &,
    .msedge & {
      width: 0;
    }

    @include media-breakpoint-only(lg) {
      z-index: 1;
      display: flex;
      background: $color-white;
      right: 60px;
      left: 0;
    }
    @include media-breakpoint-down(md) {
      fieldset {
        padding: 0;
      }
    }

    .search-results & {
      pointer-events: all;
      opacity: 1;
      position: relative;
      top: 0;
      right: auto;
      left: 0;
      width: 100%;
      background-color: $color-transparent;
      padding: 16px 24px;

      @include media-breakpoint-only(md) {
        padding: 18px 35px 0;
      }

      @include media-breakpoint-only(lg) {
        @include make-container();
        @include make-container-max-widths();

        padding: 6px 24px;
      }

      .search-bar__submit-button {
        display: block;
      }
    }
  }

  @include e(input) {
    color: $color-white;
    font-family: $ted-next;
    font-size: 14px;
    font-weight: 500;
    width: calc(100% - 36px);
    height: 100%;
    border: none;
    background-color: $color-transparent;

    &::-ms-clear {
      display: none;
    }

    &:focus {
      outline: none;
    }

    .search-results & {
      color: $color-oxford-blue;
    }

    @include media-breakpoint-only(lg) {
      color: $color-oxford-blue;
      font-size: 14px;

      .search-results & {
        width: 85%;
        font-size: 18px;
      }
    }

    &::placeholder {
      color: $color-dust-grey;
    }
  }

  @include e(input-wrapper) {
    $search-icon-width: 15px;
    $search-icon-margin-right: 16px;

    width: 100%;
    padding: 0 10px 10px 10px;
    display: flex;
    position: relative;

    @include media-breakpoint-only(lg) {
      display: flex;
      align-items: center;
      background-color: $color-transparent;
      padding-left: 0;
    }

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;

      .search-bar__input {
        height: 30px !important;
      }
      .search-bar__autocomplete {
        align-items: center;
      }
      .search-bar__search-results {
        padding-top: 0;
        padding-bottom: 0;
        background: white;
        top: 48px;
        width: 95%;
        z-index: 999;
        border: 1px solid map-get($rogers-colors, background-medium);
        li:first-child {
          margin-top: 10px;
        }
        li:last-child {
          margin-bottom: 10px;
        }
      }
    }

    &::after {
      content: '';
      width: 0;
      height: 1px;
      background-color: $color-white;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      @include media-breakpoint-only(lg) {
        background-color: $color-oxford-blue;

        .global-nav__search & {
          bottom: 0;
        }
      }
    }

    .search-results & {
      margin: 0;
      width: 100%;
      position: relative;
      background-color: $color-transparent;
      display: flex;

      &::after {
        width: 100%;
        right: inherit;
        left: 0;
        background-color: $color-oxford-blue;
      }

      @include media-breakpoint-only(lg) {
        height: 59px;
      }
    }
  }

  @include e(autocomplete) {
    display: flex;
  }
  @include e(input) {
    margin-left: 5px;
    transform: translateY(3px);
  }

  @include e(search-results) {
    position: absolute;
    top: 20px;
    left: 0px;
    list-style: none;
    padding: 10px;
    width: 100%;
  }

  @include e(search-results-item) {
    padding: 5px;
  }

  @include e(submit-button) {
    background-color: $color-transparent;
    border: none;
    color: $color-search-grey;
    display: none;

    @include m(has-value) {
      color: $color-primary-cta;
      cursor: pointer;

      &:hover {
        color: $color-primary-hover;
      }
    }

    @include media-breakpoint-only(lg) {
      display: block;
    }
  }

  @include e(close-button) {
    position: absolute;
    right: 0;
    top: 50%;
    height: 32px;
    width: 32px;
    border: 0;
    transform: translate3d(0, -50%, 0);
    background: $color-transparent;
    cursor: pointer;

    .no-touch &:hover {
      &::after,
      &::before {
        background-color: $color-azure;
      }
    }

    .search-results & {
      display: none;
    }

    &:focus {
      outline: none;
    }

    &::after {
      @include close-button-line(45deg);
    }

    &::before {
      @include close-button-line(-45deg);
    }
  }
}
